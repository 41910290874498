import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {

  private _isVisible: boolean = false;

  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
  }

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }
}
