import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';

import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { IApplicationToRequest } from '../../models/application-to-request.interface';
import { IPdnAppList } from '../../models/pdn-app-list.interface';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AddAppsService } from '../../services/add-apps.service';

type StatusOption = keyof {
  APPROVED,
  Pending
}

namespace StatusOption {
  export const approved : StatusOption = 'APPROVED';
  export const pending : StatusOption = 'Pending';
}

@Component({
  selector: 'nda-remove-application-reports',
  templateUrl: './nda-remove-application-reports.component.html',
  styleUrls: ['./nda-remove-application-reports.component.scss']
})
export class NdaRemoveApplicationReportsComponent implements OnInit {

  readonly CONSTANTS = AppStrings['applicationAndReports'];
  public applicationToRequest : IPdnAppList[];
  public isPendingApp : IPdnAppList[];

  @Input('data') data: IPdnAppList[];
  @ViewChild('selectRequestAccessForm', { static: true }) public ngForm;

  constructor(
    private drawerCommunicatorService : DrawerComponentCommunicatorService,
    private addAppsService : AddAppsService
  ) { }

  ngOnInit() {
    this.applicationToRequest = cloneDeep( this.data['applicationList'].filter((item) => item['status'] == StatusOption.approved ));
    this.isPendingApp = this.data['applicationList'].filter((item) => item['status'] == StatusOption.pending );
  }

  get hasAssignedApplications(): boolean {
    return !!this.applicationToRequest.length ;
  }

  get isApplicationSelected(): boolean {
    return !!this.selectedApplications.length;
  }

  get selectedApplications(): IPdnAppList[] {
    return this.applicationToRequest.filter((item) => item['isChecked']);
  }

  public toggleRoleSelect(): void {
    this.drawerCommunicatorService.isFormChanged = this.isApplicationSelected;
  }

  public handlerSubmitForm() : void {
    this.addAppsService.showRemoveAppConfirm = true;
    this.addAppsService.removeAppsRequest = this.selectedApplications;
    this.addAppsService.isDeleteAllApps = ( (this.applicationToRequest.length == this.selectedApplications.length) && !this.isPendingApp.length ) ? true : false;
  }

  public removeAppsErrorMessage(submitted){
    if(!!submitted && !this.isApplicationSelected){
      return this.CONSTANTS.selectARole;
    }else{return ""}
  }

  public formErrorMessage(submitted){
    if(!!submitted && !this.isApplicationSelected){
      return this.CONSTANTS.selectARole;
    }else{return ""}
  }
}
