import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';

import { ContactsService } from '../../services/contacts/contacts.service';
import { DynamicContact } from '../../models/dynamic-contact';
import { ContactDataKeys, QuicklistCategories, QuicklistRoles, QuicklistCateogies } from '../../models/contacts-object';

import { ContactDetailFlyoutComponent } from '../contact-detail-flyout/contact-detail-flyout.component';
import {Observable} from 'rxjs/Observable';
import { SessionManagementService } from '../../../services/session-management.service';

@Component({
  selector: 'app-contacts-flyout',
  templateUrl: './contacts-flyout.component.html',
  styleUrls: ['./contacts-flyout.component.scss']
})
export class ContactsFlyoutComponent implements OnInit {

  readonly CONSTANTS = {
    ...AppStrings['myContacts']['flyout'],
    'classifications': AppStrings['myContacts']['classifications'],
    'roles': AppStrings['myContacts']['roles']
  };

  isFlyoutShowing: boolean;
  contacts: any;
  accountExec: DynamicContact;
  primaryUnderwriter: DynamicContact;
  commServUnderwriter: DynamicContact;

  @Output() showFlyout = new EventEmitter<boolean>();

  constructor(
    private contactsService: ContactsService,
    private sessionManager: SessionManagementService
  ) { }

  ngOnInit() {
    this.isFlyoutShowing = false;
  }

  public getContacts(pdn: string | number) {
    this.contactsService.getContacts(pdn).subscribe(
      contacts => {

        this.contacts = contacts[ContactDataKeys.mainContactsObject][ContactDataKeys.dynamicContacts];

        if (!Object.keys(this.contacts).length) {
          return this.emptyContacts();
        }

        this.accountExec = this.contacts[QuicklistCateogies.sales]
          .filter(contact => contact.role === QuicklistRoles.accountExecutive)[0];

          this.primaryUnderwriter = this.contacts[QuicklistCateogies.underwriting]
          .filter(contact => contact.role === QuicklistRoles.primaryUnderwriter)[0];

        this.commServUnderwriter = this.contacts[QuicklistCateogies.underwriting]
          .filter(contact => contact.role === QuicklistRoles.commServUnderwriter)[0];
      },
      error => {
        if (error.status === 404) {
          this.emptyContacts();
        }
        else {
          return Observable.throw(error);
        }
      }
    );
  }

  show(show) {
    this.isFlyoutShowing = show;

    if (!this.contacts && show) {
      this.getContacts(this.sessionManager.profile.dealership.pdn);
    }
  }

  hide() {
    this.showFlyout.emit(false);
  }

  private emptyContacts(): void {
    this.accountExec = null;
    this.primaryUnderwriter = null;
    this.commServUnderwriter = null;
  }
}
