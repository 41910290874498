import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOtpComponent } from './components/modal-otp/modal-otp.component';
import { OtpService } from './services/otp/otp.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MaskEmailPipe } from './pipes/mask-email.pipe';
import { MaskPhonePipe } from './pipes/mask-phone.pipe';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    FormsModule
  ],
  declarations: [
    ModalOtpComponent,
    MaskEmailPipe,
    MaskPhonePipe
  ],
  providers: [
    OtpService
  ],
  entryComponents: [
    ModalOtpComponent
  ],
  exports: [
    ModalOtpComponent
  ]
})
export class OtpModule { }
