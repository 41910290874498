import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { get } from 'lodash';
import {Observable} from 'rxjs/Observable';

import { MatchOtherValidator } from '../../../registration/validators/match-other';
import { UserService } from '../../../services/user/user.service';

import { IChangePassword } from '../../../models/change-password.interface';
import * as AppStrings from '../../../../assets/app-strings/appStrings.json';
import { ModalPasswordChangedComponent } from '../modal-password-changed/modal-password-changed.component';
import { AppConstantsService } from '../../../services/app-constants.service';
import { PreLoginUserDetailsService } from '../../services/pre-login-user-details.service';
import { PasswordExpiryService } from '../../../services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-change-password-pre-login',
  templateUrl: './change-password-pre-login.component.html',
  styleUrls: ['./change-password-pre-login.component.scss']
})
export class ChangePasswordPreLoginComponent implements OnInit  {

  passwordForm: FormGroup;

  public isSubmitClicked: boolean;
  public showConfirmation: boolean;
  public isNewPasswordMatchesWithOldPasswordHistory: boolean;
  public isTemporaryOrExpiredPasswordInvalid: boolean;
  pwdPattern: string = "^[^&<>%=?]*$";

  public readonly CONSTANTS = {
    ...AppStrings['profile']['changePassword'],
    ...AppStrings['common']
  };

  @ViewChild('passwordChanged', { static: true  }) public passwordChanged: ModalPasswordChangedComponent;

  constructor(
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private router: Router,
    private userService: UserService,
    private appConstantsService: AppConstantsService,
    private userDetailsService: PreLoginUserDetailsService,
    private passwordExpiryService: PasswordExpiryService
  ) {
  }

  ngOnInit() {
    this.resetComponent();
  }

  public resetComponent(): void {
    this.createForm();
    this.isSubmitClicked = false;
    this.showConfirmation = false;
    this.isNewPasswordMatchesWithOldPasswordHistory = false;
    this.isTemporaryOrExpiredPasswordInvalid = false;
  }

  public get showOldPasswordError(): boolean {
    return this.passwordForm.controls.oldPassword.value.length === 0 && this.isSubmitClicked;
  }

  public get showEmptyNewPasswordError(): boolean {
    return this.passwordForm.controls.newPassword.value.length === 0 && this.isSubmitClicked;
  }

  public get isPasswordHasRestrictedCharactersInOldPassword() {
    return  this.isSubmitClicked && get(this.passwordForm.controls.oldPassword , 'errors.pattern');
  }

  public get isPasswordHasRestrictedCharactersInNewPassword() {
    return  this.isSubmitClicked && get(this.passwordForm.controls.newPassword, 'errors.pattern');
  }

 public get isPasswordHasRestrictedCharactersInNewConfirmPassword() {
    return  this.isSubmitClicked && get(this.passwordForm.controls.passwordConfirmation, 'errors.pattern');
  }

  public get showGenericNewPasswordError(): boolean {
    return !this.passwordForm.controls.newPassword.valid
      && this.isSubmitClicked
      && this.passwordForm.controls.newPassword.value.length > 0;
  }

  public get showReEnterPasswordError(): boolean {
    return !this.passwordForm.controls.passwordConfirmation.valid
      && this.isSubmitClicked;
  }

  public get oldPasswordErrorMessage(){
    if (!!this.showOldPasswordError){
      return this.CONSTANTS.errors.currentPassword;
    }
    if(!!this.showInvalidTemporaryOrExpiredPasswordError){
      return this.CONSTANTS.errors.invalidCurrentPassword;
    }
    if (!!this.isPasswordHasRestrictedCharactersInOldPassword){
      return this.CONSTANTS.errors.invalidPassword;
    }else{return "";}
  }

  public newPasswordErrorMessage(passMatches){
    if (!!this.showEmptyNewPasswordError){
      return this.CONSTANTS.errors.newPasswordRequired;
    }
    if(!!this.showGenericNewPasswordError){
      return this.CONSTANTS.errors.newPasswordGeneric;
    }
    if (!!this.showNewPasswordMatchesWithOldPasswordHistoryError){
      return this.CONSTANTS.errors.newPasswordMatchesPreviousPassword;
    }
    if (!!passMatches && !!this.isSubmitClicked){
      return this.CONSTANTS.errors.passwordMatchesUsername;
    }
    if (!!this.isPasswordHasRestrictedCharactersInNewPassword){
      return this.CONSTANTS.errors.invalidPassword;
    }else{return "";}
  }

  public get reEnterNewPasswordErrorMessage(){
    if (!!this.showReEnterPasswordError){
      return this.CONSTANTS.errors.reEnterPassword;
    }
    if(!!this.isPasswordHasRestrictedCharactersInNewConfirmPassword){
      return this.CONSTANTS.errors.invalidPassword;
    }else{return "";}
  }
  



  private createForm(): void {
    this.passwordForm = this.fb.group({
      username: [this.userDetailsService.userName || ''],
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        this.validatePasswordComplexity,
        this.validatePasswordNotMatchUsername
      ])],
      passwordConfirmation: ['', [
        Validators.required,
        this.validatePasswordComplexity,
        MatchOtherValidator('newPassword')
      ]]
    });
  }

  public validatePasswordComplexity(c: FormControl): {} {
    const PASSWORD_COMPLEXITY = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

    return PASSWORD_COMPLEXITY.test(c.value) ? null : {
      validatePassword: {
        valid: false
      }
    };
  }

  private validatePasswordNotMatchUsername(control: FormControl): {} {
    const password = control.value;
    const usernameObject = control.root.get('username');
    const username = usernameObject ? usernameObject.value : '';

    return !(password.toLowerCase() === username.toLowerCase())
      ? null
      : { passwordMatchesUsername: true };
  }

  public onSubmit(): void {
    this.isSubmitClicked = true;
    this.isNewPasswordMatchesWithOldPasswordHistory = false;
    this.isTemporaryOrExpiredPasswordInvalid = false;
    if (this.passwordForm.valid) {
      const changePasswordModel: IChangePassword = {
        oldPassword: this.passwordForm.controls.oldPassword.value,
        newPassword: this.passwordForm.controls.newPassword.value
      };
      this.spinnerService.show();
      const sessionDetails = this.userDetailsService.sessionDetails;

      this.userService.changePassword(changePasswordModel, sessionDetails['userId'])
        .finally(() => this.spinnerService.hide())
        .subscribe(() => this.passwordChanged.show(),
          err => this.handleError(err));
    }
  }

  public cancelForm(): void {
    const downstreamAppIdentifier = this.passwordExpiryService.downstreamAppIdentifier;
    if (downstreamAppIdentifier) {
        this.navigateToExternalUrl(downstreamAppIdentifier);
    } else {
      this.router.navigate(['/login']);
    }
  }

  private navigateToExternalUrl(downstreamAppIdentifier: string): void {
    window.location.assign(environment.downstreamAppURL[downstreamAppIdentifier]['loginPageURL']);
  }

  public get showNewPasswordMatchesWithOldPasswordHistoryError() {
    return this.isSubmitClicked && this.isNewPasswordMatchesWithOldPasswordHistory;
  }

  public get showInvalidTemporaryOrExpiredPasswordError() {
    return this.isSubmitClicked && this.isTemporaryOrExpiredPasswordInvalid;
  }

  public handleError(err: HttpErrorResponse) {
    const errorResponse = get(err, 'error.exceptions');
    if (errorResponse && errorResponse['code'] === 'ERR_000027') {
      this.isNewPasswordMatchesWithOldPasswordHistory = true;
    } else if (errorResponse && errorResponse['code'] === 'ERR_000028') {
      this.isTemporaryOrExpiredPasswordInvalid = true;
    } else {
      return Observable.throw(err);
    }
  }

}
