import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { HttpClient } from '@angular/common/http';
import { SessionManagementService } from './app/services/session-management.service';

if (environment.production) {
  enableProdMode();
}
const script = document.createElement('script');
script.setAttribute('async', 'async');
script.setAttribute('src', environment.adobeLaunchSdk);
document.head.appendChild(script);
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(AppModule => {
    const applicationRef = AppModule.injector.get(ApplicationRef);
    const appComponent   = applicationRef.components[0];

    class NdaApplicationContextFacade {

      get hasSession(): boolean {
        let sessionManager = appComponent.injector.get(SessionManagementService);
        return sessionManager.hasSession;
      }

      get http(): HttpClient {
        let httpClient = appComponent.injector.get(HttpClient);
        return httpClient;
      }
    }

    window['NDA_Application_Context'] = new NdaApplicationContextFacade();
  })
  .catch(err => console.log(err));
