import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as AppStrings from "../../../../assets/app-strings/appStrings.json";
import { ErrorType } from "../../handlers/errors-handler";

import {get} from "lodash";

interface ErrorMessage {
  body: string;
  title: string;
}
@Component({
  selector: 'app-dealer-apps',
  templateUrl: './dealer-apps.component.html',
  styleUrls: ['./dealer-apps.component.scss']
})
export class DealerAppsComponent implements OnInit {
  readonly CONSTANTS = {
    errors: get(AppStrings['default'], 'errorApps')
  };


  constructor(private activatedRoute: ActivatedRoute) {}

  private get paramAppId(): string {
    return get(this.activatedRoute,'snapshot.params.appId','');
  }

  get error(): ErrorMessage {
    let appId = this.paramAppId.toLowerCase();
    return get(this.CONSTANTS.errors, appId, this.CONSTANTS.errors[ErrorType.Default]);
  }

  ngOnInit() {

  }

}
