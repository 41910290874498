import { Injectable } from '@angular/core';
import { UserModel } from '../models/user-model';

export type PasswordStatus = keyof {
  PASSWORD_WARN,
  PASSWORD_EXPIRED
};
export namespace PasswordStatus {
  export const PasswordWarn: PasswordStatus = 'PASSWORD_WARN';
  export const PasswordExpired: PasswordStatus = 'PASSWORD_EXPIRED';
}

@Injectable()
export class PasswordExpiryService {

  public status: string;
  public passwordExpiryDate: string[];
  public downstreamAppIdentifier: string;

  constructor() { }

}
