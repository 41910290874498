<div class="shade" [class.active]="isFlyoutShowing"></div>

<div class="container accessibility-link-group">
  <div class="row">
    <div class="col-12 text-right">
      <i (click)="hide()" class="btn-close allycon allycon-glyph-close text-primary"></i>
    </div>
  </div>

  <div class="row mb-5" *ngIf="accountExec">
    <div class="col-md-6">
      <h5><b>{{ CONSTANTS.roles.ACC_EXE }}</b></h5>
    </div>
    <div class="col-md-6">
      <app-contact-detail-flyout [contact]="accountExec"></app-contact-detail-flyout>
    </div>
  </div>

  <div class="row mb-5" *ngIf="primaryUnderwriter || commServUnderwriter">
    <div class="col-md-6">
      <h5><b>{{ CONSTANTS.classifications.UNDRW }}</b></h5>
    </div>
    <div class="col-md-6">
      <app-contact-detail-flyout [contact]="primaryUnderwriter"></app-contact-detail-flyout>
      <br>
      <app-contact-detail-flyout [contact]="commServUnderwriter"></app-contact-detail-flyout>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <h5><b>{{CONSTANTS.classifications.helpDesk}}</b></h5>
    </div>
    <div class="col-md-6">
        <p class="text-primary"><a href="tel:866-766-4622">866-766-4622</a></p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-6">
      <a (click)="hide()" routerLink="/contacts">{{ CONSTANTS.viewAllContacts }}</a>
    </div>
  </div>
</div>

