<header class="nda-header">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex">
        <a (click)="dashboardRefresh()"><img src="assets/images/Ally_Logo_White_png.png" class="logo" alt="Ally Logo. Return to Ally Dash homepage."/></a>
        <nda-nav *ngIf="shouldDisplayNav" class="ml-auto" [isFlyoutShowing]="isFlyoutShowing" (showFlyout)="displayFlyout($event)"></nda-nav>
      </div>
    </div>
  </div>
</header>

<div class="container">
  <app-contacts-flyout [class.active]="isFlyoutShowing" (showFlyout)="displayFlyout($event)"></app-contacts-flyout>
</div>
