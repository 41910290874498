export type ProfileUserType = keyof {
  B, D
}
export namespace ProfileUserType {
  export const branch: ProfileUserType = 'B';
  export const dealership: ProfileUserType = 'D';
}

export type ProfileDealerRole = keyof {
  'Dealer User',
  'Dealer Admin'
}
export namespace ProfileDealerRole {
  export const user: ProfileDealerRole = 'Dealer User';
  export const admin: ProfileDealerRole = 'Dealer Admin';
}

export type ProfileAllyRole = keyof {
  'Ally User',
  'Ally Admin',
  'Help Desk'
}
export namespace ProfileAllyRole {
  export const user: ProfileAllyRole = 'Ally User';
  export const admin: ProfileAllyRole = 'Ally Admin';
  export const helpdesk: ProfileAllyRole = 'Help Desk';
}

export type ProfileBirthrightAccess = keyof {
  'EMP_BirthrightAccess',
  'Dealer_BirthrightAccess',
}

export namespace ProfileBirthrightAccess {
  export const branch: ProfileBirthrightAccess = 'EMP_BirthrightAccess';
  export const dealership: ProfileBirthrightAccess = 'Dealer_BirthrightAccess';
}

export type ProfileRideReturnAccess = keyof {
  'Dealer_fullAccess',
  'Dealer_PrimaryAccess',
  'Dealer_SecondaryAccess',
  'EMP_PrimaryAccess'
}


export namespace ProfileRideReturnGroup {
  export const fullAccess: ProfileRideReturnAccess = 'Dealer_fullAccess';
  export const primaryAccess: ProfileRideReturnAccess = 'Dealer_PrimaryAccess';
  export const secondaryAccess: ProfileRideReturnAccess = 'Dealer_SecondaryAccess';
  export const branchAccess: ProfileRideReturnAccess = 'EMP_PrimaryAccess';
}

export interface IUserDealership {
  pdn: string;
  name: string;
}

export interface IProfile {
  firstName: string;
  lastName: string;
  login: string;
  locale: string;
  timeZone: string;
  email?: string;
  userId?: string;
  userName?: string;
  userType?: ProfileUserType;
  allyRole?: ProfileAllyRole;
  dealerRole?: ProfileDealerRole;
  dealership?: IUserDealership;
  birthright?: ProfileBirthrightAccess;
  allyrideReturnGroup?: ProfileRideReturnAccess;
  managerId?: string;
  managerEmail?: string;
  mobilePhone?: string
}
