import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { template } from 'lodash';
import { AppStrings } from '../../../../assets/app-strings/app-strings';


@Component({
  selector: 'session-timeout',
  templateUrl: './modal-session-timeout.component.html',
  styleUrls: ['./modal-session-timeout.component.scss']
})
export class ModalSessionTimeoutComponent {

  readonly CONSTANTS = {
    ...AppStrings['common'],
    ...AppStrings['modalSessionTimeOut']
  };

  didRefresh: Subject<boolean> = new Subject();
  didLogout: Subject<boolean> = new Subject();

  message: string;

  public refreshSession(): void {
    this.didRefresh.next(true);
  }

  public logOut(): void {
    this.didLogout.next(true);
  }

  public countDownMessage(seconds): void {
    let now  = new Date().getTime(),
        then = new Date().getTime() + (seconds * 1000),
        diff = then - now,
        mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        secs = Math.floor((diff % (1000 * 60)) / 1000),
        displaySecs = secs >= 10 ? secs : `0${secs}`;

    let unitMins  = mins > 1  ? 'minutes' : 'minute';
    let unitSecs  = secs == 1 ? 'second' : 'seconds';

    let countdown = (mins ? `${mins} ${unitMins} ` : '') + `${displaySecs} ${unitSecs}`;

    this.message = template(this.CONSTANTS.message)({ countdown });
  }
}



