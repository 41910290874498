import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NdaDrawerComponent } from './components/nda-drawer/nda-drawer.component';
import { DrawerComponentsService } from './services/drawer-components.service';
import { DrawerComponentCommunicatorService } from './services/drawer-component-communicator.service';

import { DrawerDirective } from './directives/drawer.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ NdaDrawerComponent, DrawerDirective ],
  providers: [ DrawerComponentsService, DrawerComponentCommunicatorService ],
  exports: [
    NdaDrawerComponent
  ]
})
export class DrawerModule { }
