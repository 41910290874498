import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-logout',
  template: ``,
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private analytics: AnalyticsService
  ) { }

  ngOnInit() {
    setTimeout(() => { this.analytics.trackPage({ title: 'ADOS:Logout' }) });
    this.logout();
  }

  logout() {
    this.authService.logout();
  }

}
