import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { StoreService } from '../../services/store.service';
import { IFlowMap } from '../../models/flow-map.interface';
import { AppConstantsService } from '../../services/app-constants.service';
import { SessionManagementService } from '../../services/session-management.service';

@Injectable()
export class RegisterGuard implements CanActivate {

  private flowMap: IFlowMap;

  constructor(
    private router: Router,
    private storeService: StoreService,
    private appConstantsService: AppConstantsService,
    private sessionManager: SessionManagementService,
  ) {
    this.flowMap = this.appConstantsService.pdn_non_verified_register_flow;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (this.sessionManager.willDeleteSessionOnNextVisit) {
        this.router.navigate(['/login']);
        return false;
      }

      return true;
  }
}
