import { Component, OnInit } from '@angular/core';
import { flatMap, first, get } from 'lodash';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/do';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';

import { IDealership } from '../../../registration/models/dealership.interface';
import { DealershipService } from '../../../registration/services/dealership/dealership.service';

import { DynamicContact } from '../../models/dynamic-contact';
import { GeneralContact } from '../../models/general-contact';
import { ContactsService } from '../../services/contacts/contacts.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { ContactDataKeys } from '../../models/contacts-object';
import { SessionManagementService } from '../../../services/session-management.service';
import { AnalyticsService } from '../../../services';

interface SalesTeam {
  name?: string;
  PRI_ACC_EXE?: DynamicContact[];
  PRI_TEAM?: DynamicContact[];
  SUPP_TEAM?: DynamicContact[];
  MGMT_TEAM?: DynamicContact[];
}

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {

  readonly CONSTANTS = {
    ...AppStrings['common'],
    ...AppStrings['searchUsers'],
    ...AppStrings['myContacts']['contactDetail'],
    ...AppStrings['myContacts']['contactsList'],
    ...AppStrings['myContacts']['salesTeams'],
    'classifications': AppStrings['myContacts']['classifications'],
    'roles': AppStrings['myContacts']['roles']
  };

  readonly filterKeys = ['QUICKLIST__SALES', 'QUICKLIST__UNDRW', '__'];
  readonly accountExecKey = 'SALES_AUTO_FIN_PRI_ACC_EXE';
  readonly underwriterRoles = ['PRIME', 'N_PRIME', 'COMM_SERV', 'ACQ_MGR'];
  readonly salesLineKeys = ['AUTO_FIN', 'INS', 'REMRKT', 'WH_DL'];
  readonly salesTeamKeys = ['PRI_ACC_EXE', 'PRI_TEAM', 'SUPP_TEAM', 'MGMT_TEAM'];
  readonly salesLineTitlesFilter = {
    'Insurance': 'F&I and Dealer Insurance'
  };

  lineVisible = {};
  visibleTeam: string;
  visibleGC: string;
  salesTeamNames = 'Auto Finance';
  contacts: any;
  accountExec: DynamicContact;
  underwriters: object;
  autoFinanceSales: SalesTeam;
  salesTeams: object;
  generalContacts: GeneralContact[];
  generalContactsHash: object;
  generalVisible: object;
  isSubmitClicked: boolean;
  isNoDealershipFound: boolean;

  dealership: IDealership;
  hasLoaded = false;
  query: string;

  get pageTitle(): string {
    return this.CONSTANTS[ this.isBranchUser ? 'searchContacts' : 'contact' ];
  }

  constructor(
    private contactsService: ContactsService,
    private spinnerService: SpinnerService,
    private dealershipService: DealershipService,
    private sessionManager: SessionManagementService, 
    private analytics: AnalyticsService
  ) {
    this.salesTeams = {};
    this.underwriters = {};
    this.generalContactsHash = {};
    this.generalVisible = {};
    this.isSubmitClicked = false;
    this.isNoDealershipFound = false;
  }

  public contactsListSearchErrorMessage(search){
    if (!!this.isSubmitClicked && !!this.isPdnEmpty){
      return this.CONSTANTS.errors.pdnRequired;
    }
    if(!!this.isSubmitClicked && (!!search || !!this.isNoDealershipFound) && !this.isPdnEmpty){
      return this.CONSTANTS.errors.pdnNotFound;
    }else{return ""}
  }

  ngOnInit() {
    this.salesLineKeys.forEach(key => this.lineVisible[key] = false);

    if (!this.sessionManager.profile.dealership) {
      this.emptyDynamicContacts();
      this.emptyGeneralContacts();
      return;
    } else {
      this.getContacts(this.sessionManager.profile.dealership.pdn);
    }

    setTimeout(() => { this.analytics.trackPage({ title: 'ADOS:Contacts' }) });
  }

  private emptyDynamicContacts(): void {
    this.accountExec = null;
    this.underwriters = {};
    this.salesTeams = {};
  }

  private emptyGeneralContacts(): void {
    this.generalContacts = [];
    this.generalContactsHash = {};
  }

  private parseContacts(contacts: any): void {
    this.contacts = contacts[ContactDataKeys.mainContactsObject];

    if (Object.keys(this.contacts[ContactDataKeys.dynamicContacts]).length !== 0) {

      this.accountExec = this.contacts[ContactDataKeys.dynamicContacts][this.accountExecKey][0];

      const contactsArray = Object.keys(this.contacts[ContactDataKeys.dynamicContacts])
        .map(key => [key, this.contacts[ContactDataKeys.dynamicContacts][key]])
        .filter(tuple => !(this.filterKeys.some(value => value === tuple[0])))
        .map(tuple => tuple[1]);
      const flatContacts: any = flatMap(contactsArray);

      this.underwriterRoles.forEach(role => {
        this.underwriters[role] = flatContacts.filter(contact => contact.role === role)[0];
      });

      this.salesLineKeys.forEach(key => {
        const team: SalesTeam = {
          name: key
        };

        const teamMembers = flatContacts.filter(contact => contact.busLine === key);
        teamMembers.forEach(member => {
          if (typeof team[member.group] === 'undefined') {
            team[member.group] = [];
          }

          team[member.group].push(member);
        });

        this.salesTeams[key] = team;
      });
    } else {
      this.emptyDynamicContacts();
    }

    const generalContactsData = this.contacts[ContactDataKeys.generalContacts];
    if (generalContactsData && generalContactsData.length !== 0) {
      this.generalContacts = generalContactsData.generalContactList
        .sort((contact1, contact2) => contact1.title.localeCompare(contact2.title));

      this.generalContacts.forEach(contact => {
        this.generalContactsHash[contact.title] = contact;
      });
    } else {
      this.emptyGeneralContacts();
    }
  }

  toggleLine(line: string): void {
    this.lineVisible[this.visibleTeam] = false;

    if (this.visibleTeam !== line) {
      this.lineVisible[line] = true;
      this.visibleTeam = line;
    } else {
      this.visibleTeam = '';
    }
  }

  toggleGC(gc: string): void {
    this.generalVisible[this.visibleGC] = false;

    if (this.visibleGC !== gc) {
      this.generalVisible[gc] = true;
      this.visibleGC = gc;
    } else {
      this.visibleGC = '';
    }
  }

  lineCaretType(line): string {
    return this.lineVisible[line] ? 'allycon-glyph-caret-down' : 'allycon-glyph-caret-right';
  }

  generalCaretType(title): string {
    return this.generalVisible[title] ? 'allycon-glyph-caret-down' : 'allycon-glyph-caret-right';
  }

  get isPdnEmpty(): boolean {
    return !this.query || this.query.length < 1;
  }

  public searchByPDN(pdn: string, valid: boolean) {
    this.isSubmitClicked = true;

    if (!valid) {
      return;
    }

    this.spinnerService.show();

    this.dealershipService.findByPdn(pdn)
      .do(() => this.isSubmitClicked = false)
      .finally(() => this.spinnerService.hide())
      .subscribe(dealerships => {
        if (dealerships.length) {
          this.dealership = first<IDealership>(dealerships);
          this.getContacts(pdn);
        }

        this.isNoDealershipFound = !dealerships.length;
      });
  }


  private getContacts(pdn: string) {
    this.spinnerService.show();

    this.contactsService.getContacts(pdn)
      .do(() => this.hasLoaded = true)
      .finally(() => this.spinnerService.hide())
      .subscribe(contacts => this.parseContacts(contacts));
  }

  get salesLinesEmpty(): boolean {
    return Object.keys(this.salesTeams).length !== 0;
  }

  get underwritersEmpty(): boolean {
    return Object.keys(this.underwriters).length !== 0;
  }

  get primaryTeamEmpty(): boolean {
    return this.underwriters[this.underwriterRoles[0]]
      || this.underwriters[this.underwriterRoles[1]]
      || this.underwriters[this.underwriterRoles[2]];
  }

  get filteredSalesLineKeys(): string[] {
    return this.salesLineKeys.filter(key => Object.keys(this.salesTeams[key]).length > 1);
  }

  salesLineEmpty(line: string) {
    return Object.keys(this.salesTeams[line]).length < 2;
  }

  getMappedData(key: string, data: string): object {
    let mappedData = this.getData(key, data);
    return get(AppStrings, `myContacts.mappings.${mappedData}`, mappedData);
  }

  getData(key: string, data: string): object {
    return this.generalContactsHash[key][data];
  }

  get isBranchUser(): boolean {
    return this.sessionManager.isBranchUser;
  }

  get isDealershipUser(): boolean {
    return this.sessionManager.isDealershipUser;
  }

  tel(phoneNumber: string): string {
    return `tel:${phoneNumber}`;
  }

  mailto(email: string): string {
    return `mailto:${email}`;
  }
}
