import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<Object> = new Subject<Object>();
  context: {};
  userKey: string;

  constructor() {
    /* 
      Add newly created flags from DASH LD Project here to initialize them and set a default value
      { string: any }
    */
    this.flags = {
      'document-manager': false,
      'dash_app_adr': false,
      'dash_app_sc': false,
    }

    // Sets the user key to whatever environment we are in
    this.userKey = 'key-user-' + environment.env;

    // Passes user information to LaunchDarkly
    this.context = {
      key: this.userKey,
      kind: 'user',
    };
 
    // Uses Environment Specific Client ID and user context to initialize connection to LaunchDarkly 
    this.ldClient = initialize(environment.launchDarkly.clientSideId, this.context);

    // If properly connected and initialized
    this.ldClient.on('initialized', () => {
      console.log("LaunchDarkly flags sucessfully initialized.");
    });

    // If failed to properly connect and initialize
    this.ldClient.on('failed', () => {
      console.log("LaunchDarkly flags failed to initialize.");
    });

    /* 
      Will run to gather flag data regardless of success or failure
      Will loop through all flags that were set up above in this.flags
    */
    this.ldClient.on('ready', (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {
        this.ldClient.variation(key, value);
        this.flags[key] = flags[key];
      })
    });
 
    // Tracks changes made to flags
    this.ldClient.on('change', (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {      
      if(flags[key] !== undefined) {
        this.flags[key] = flags[key];
      }
      this.flagChange.next(this.flags);
      })
      console.log("LaunchDarkly flags updated.");
    })
  }
}