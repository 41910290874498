import { Component, OnInit, Input } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { MatchOtherValidator} from '../../../registration/validators/match-other';
import { PasswordComplexityComponent } from '../../../password-complexity/components/password-complexity/password-complexity.component';
import { DrawerOutputState, DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { IUserDetails } from '../../models/user-details.interface';
import { get } from 'lodash';
import {Observable} from 'rxjs/Observable';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-nda-modal-content-create-password',
  templateUrl: './nda-modal-content-create-password.component.html',
  styleUrls: ['./nda-modal-content-create-password.component.scss']
})
export class NdaModalContentCreatePasswordComponent implements OnInit {

  readonly CONSTANTS = {
    ...AppStrings['createPassword'],
    ...AppStrings['common']
  };

  passwordForm: FormGroup;
  public isSubmitClicked: boolean;
  public showConfirmation: boolean;
  public showError: boolean;
  public profile : IUserDetails;
  public isPasswordMatchesWithOldPasswordHistory: boolean;
  pwdPattern: string = "^[^&<>%=?]*$";

  @Input('data')
  data: any;

  constructor(
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private drawerComponentCommunicatorService: DrawerComponentCommunicatorService,
    private userDetailsService: UserDetailsService,
    private router: Router
  ) {

  }

  public resetComponent(): void {
    this.createForm();
    this.isSubmitClicked = false;
    this.showConfirmation = false;
    this.isPasswordMatchesWithOldPasswordHistory = false;
  }

  ngOnInit() {
    this.profile = {
      userId: null,
      userType: null,
      pdnWithApplications : null,
      firstName: null,
      lastName: null,
      cellPhone: null,
      emailId: null,
      primaryDealer: null,
      reEnterEmailId: '',
      userName: '',
      dealershipName: '',
      userStatus: '',
      dealerRole: null,
      allyRole: null
    };

    this.profile = this.data.profile;
    if(this.isPhoneNumberWithPlus) {
      this.profile.cellPhone = this.profile.cellPhone.slice(2);
    }

    this.resetComponent();

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.resetComponent();
      }
    });

  }

  public createPasswordFormErrorMessage(passMatches){
    if (!!this.showEmptyNewPasswordError){
      return this.CONSTANTS.errors.newPasswordRequired;
    }
    if(!!this.showGenericNewPasswordError){
      return this.CONSTANTS.errors.newPasswordGeneric;
    }
    if(!!passMatches && !!this.isSubmitClicked){
      return this.CONSTANTS.errors.passwordMatchesUsername;
    }
    if(!!this.showPasswordMatchesWithOldPasswordHistory){
      return this.CONSTANTS.errors.passwordMatchesPreviousPassword;
    }
    if(!!this.isPasswordHasRestrictedCharactersInNewPassword){
      return this.CONSTANTS.errors.invalidPwd;
    }else{return "";}
  }

  public get reEnterPasswordFormErrorMessage(){
    if(!!this.isPasswordHasRestrictedCharactersInNewPassword){
      return this.CONSTANTS.errors.invalidPwd;
    }
    if (!!this.showReEnterPasswordError){
      return this.CONSTANTS.errors.reEnterPassword;
    }else{return "";}
  }

  public get isPhoneNumberWithPlus(): boolean {
    return this.profile.cellPhone !== null && this.profile.cellPhone !== undefined && this.profile.cellPhone.startsWith('+');
  }

  private createForm(): void {
    this.passwordForm = this.fb.group({
      username: [this.profile.userName],
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        this.validatePasswordComplexity,
        this.validatePasswordNotMatchUsername
        ])],
      passwordConfirmation: ['', [
        Validators.required,
        MatchOtherValidator('newPassword')
      ]]
    });
  }

  private validatePasswordComplexity(c: FormControl): {} {
    const PASSWORD_COMPLEXITY = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

    return PASSWORD_COMPLEXITY.test(c.value) ? null : {
      validatePassword: {
        valid: false
      }
    };
  }

  private validatePasswordNotMatchUsername(control: FormControl): {} {
    const password = control.value;
    const usernameObject = control.root.get('username');
    const username = usernameObject ? usernameObject.value : '';

    return !(password.toLowerCase() === username.toLowerCase())
      ? null
      : { passwordMatchesUsername: true };
  }


  public get showEmptyNewPasswordError() {
    return this.passwordForm.controls.newPassword.value.length === 0 && this.isSubmitClicked;
  }

  public get showGenericNewPasswordError() {
    return !this.passwordForm.controls.newPassword.valid
      && this.isSubmitClicked
      && this.passwordForm.controls.newPassword.value.length > 0;
  }

  public get showPasswordMatchesWithOldPasswordHistory() {
    return this.isSubmitClicked && this.isPasswordMatchesWithOldPasswordHistory;
  }

  public get showReEnterPasswordError() {
    return !this.passwordForm.controls.passwordConfirmation.valid
      && this.isSubmitClicked;
  }

  public get isFormChanged() {
    this.drawerComponentCommunicatorService.isFormChanged = this.passwordForm.controls.newPassword.value.length != 0 || this.passwordForm.controls.passwordConfirmation.value.length != 0;
    return true;
  }

  public get isPasswordHasRestrictedCharactersInNewPassword() {
    return  this.isSubmitClicked && (get(this.passwordForm.controls.newPassword, 'errors.pattern') || get(this.passwordForm.controls.passwordConfirmation, 'errors.pattern'));
  }

  public onSubmit() {
    this.isSubmitClicked = true;
    this.isPasswordMatchesWithOldPasswordHistory = false;

    if (this.passwordForm.valid) {
      this.spinnerService.show();
      this.userDetailsService.createTemporaryPassword(this.profile, this.passwordForm.controls.newPassword.value)
      .finally(() => this.spinnerService.hide())
      .subscribe(response => this.processCreateTemporaryPasswordReponse(response),
                 err =>this.handleError(err));
    }
  }

  public processCreateTemporaryPasswordReponse(response): void {
    this.passwordForm.controls.newPassword.setValue('');
    this.passwordForm.controls.passwordConfirmation.setValue('');
    this.drawerComponentCommunicatorService.isFormChanged = false;

    if(response == null) {
      this.drawerComponentCommunicatorService.callOnloadServiceOnClose = true;
      this.showConfirmation = true;
    } else {
      this.showError = true;
    }
  }

  private handleError(err: HttpErrorResponse) {
    let errorResponse = get(err, "error.exceptions");
    if(errorResponse && errorResponse['code'] == `ERR_000027`) {
      this.isPasswordMatchesWithOldPasswordHistory = true;
    } else {
      return Observable.throw(err);
    }
  }

}
