import { Component, OnInit, Input } from '@angular/core';

import { DynamicContact } from '../../models/dynamic-contact';
import { GeneralContact } from '../../models/general-contact';
import { AppStrings } from '../../../../assets/app-strings/app-strings';


@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  readonly CONSTANTS = {
    ...AppStrings['myContacts']['contactDetail'],
  };

  @Input() contact: DynamicContact | GeneralContact;
  @Input() layout: 'horizontal' | 'vertical';

  constructor() { }

  ngOnInit() { }

  isDynamicContact(contact: DynamicContact | GeneralContact): contact is DynamicContact {
    return (<DynamicContact>contact).contactName !== undefined;
  }

  get columns(): string {
    return this.layout === 'horizontal' ? 'col-4' : 'col-12';
  }

  get row(): string {
    return this.layout === 'horizontal' ? 'row' : '';
  }
} 
