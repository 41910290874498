import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { StoreService } from '../../services';
import { last, lowerCase, snakeCase } from 'lodash';
import { NavigatorDetails } from './navigator-details';
import 'rxjs/add/operator/map'

export type ErrorType = keyof {
  default,
  offline
}

export namespace ErrorType {
  export const Default: ErrorType = 'default';
  export const Offline: ErrorType = 'offline';
}

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  get baseUrl(): string {
    const base = document.querySelector('base');
    return ((base && base.getAttribute('href')) || '').replace(/\/$/, '');
  }

  get isOffline(): boolean {
    return NavigatorDetails.isOffline;
  }

  get clientBrowser(): string {
    return NavigatorDetails.clientBrowser;
  }

  constructor(private storeService: StoreService) { }

  handleError(error: Error | HttpErrorResponse) {
    if (this.isOffline) {
      const params = new HttpParams().set('type', ErrorType.Offline);
      this.handleRenderError(params);
    }
    else if (error instanceof HttpErrorResponse) {
      let requestSegments = error.url.split('?'),
          requestUrl      = requestSegments[0],
          requestParms    = requestSegments[1] && requestSegments[1].split('&');

      let params = new HttpParams()
        .set('browser',     this.clientBrowser)
        .set('referrerUrl', last(location.href.split(location.origin)))
        .set('requestUrl', requestUrl);

      if (requestParms) {
        requestParms.forEach(param => {
          let [key, val] = param.split('=');
          params = params.set(key, val);
        });
      }

      this.storeService.write('HttpErrorResponse', error);
      this.handleRenderError(params);
    }
    else {
      console.error(error);
    }
  }

  private handleRenderError(params?: HttpParams): void {
    window.location.href = `${this.baseUrl}/error/?${params.toString()}`;
  }
}
