export type ContactDataKeys = keyof {
    'myContactsMap', 'DYNAMICCONTACTS', 'GENERALCONTACTS'
};

export namespace ContactDataKeys {
    export const mainContactsObject: ContactDataKeys = 'myContactsMap';
    export const dynamicContacts: ContactDataKeys = 'DYNAMICCONTACTS';
    export const generalContacts: ContactDataKeys = 'GENERALCONTACTS';
}

export type QuicklistCategories = keyof {
    'QUICKLIST__SALES', 'QUICKLIST__UNDRW'
};

export namespace QuicklistCateogies {
    export const sales: QuicklistCategories = 'QUICKLIST__SALES';
    export const underwriting: QuicklistCategories = 'QUICKLIST__UNDRW';
}

export type QuicklistRoles = keyof {
    'ACC_EXE_QL', 'PRIME_QL', 'COMM_SERV_QL'
};

export namespace QuicklistRoles {
    export const accountExecutive: QuicklistRoles = 'ACC_EXE_QL';
    export const primaryUnderwriter: QuicklistRoles = 'PRIME_QL';
    export const commServUnderwriter: QuicklistRoles = 'COMM_SERV_QL';
}
