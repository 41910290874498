<div bsModal #passwordChanged="bs-modal" class="modal fade modal-otp" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="ally-primary-heading pull-left"> {{CONSTANTS.thankYou}} </h3>
          <button type="button" class="close pull-right text-primary" aria-label="Close" (click)="hide()">
              <i class="allycon-glyph-close"></i>
          </button>
        </div>
        <div class="modal-body">
            <form [attr.allytmfn]="'pwdChangedForm'" #pwdChangedForm="ngForm" class="form-horizontal" name="otpSendSecCode">
              <div class="form-group row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <p>{{CONSTANTS.pwdChanged}}</p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <button type="button" (click)="hide()" class="btn btn-primary font-weight-bold">{{CONSTANTS.goToLogin}}</button>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  </div>