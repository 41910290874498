import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../services/app-constants.service';
import { PasswordExpiryService } from '../../services/password-expiry.service';

import { environment } from '../../../environments/environment';
import { SessionManagementService } from '../../services/session-management.service';

@Component({
  selector: 'password-warn',
  templateUrl: './password-warn.component.html',
  styleUrls: ['./password-warn.component.scss']
})
export class PasswordWarnComponent implements OnInit {

  readonly CONSTANTS = AppStrings['passwordExpiry'];
  @ViewChild('passwordWarn', { static: true }) public passwordWarn: ModalDirective;

  public getDate : Date;
  public dateFormat: String  = "MM/dd/yy";
  constructor(
    private router: Router,
    private appConstantsService : AppConstantsService,
    private passwordExpiryService : PasswordExpiryService,
    private sessionManager: SessionManagementService,
  ) { }

  ngOnInit() {
  }

  public show(passwordChangedDate){
    this.getDate = this.convertObjectToDate(passwordChangedDate)
    this.passwordWarn.show();
  }

  public hide(isOnlyHide ?: boolean){
    // If Smart Auction Flow then navigate them to SmartAuction landing page
    if (this.isDownStreamFlow && !isOnlyHide) {
      let sessionToken = this.sessionManager.sessionToken;
      window.location.assign(environment.downstreamAppURL[this.passwordExpiryService.downstreamAppIdentifier]['landingPageURL']+sessionToken);
    } else {
      this.passwordWarn.hide();
    }
  }

  public changePassword(){
    this.hide(true);
    this.router.navigate([this.appConstantsService.post_login_change_password]);
  }

  public convertObjectToDate(pwdExpiryDate){
    return new Date(pwdExpiryDate.year, pwdExpiryDate.monthValue - 1, pwdExpiryDate.dayOfMonth);
  }

  public get isDownStreamFlow() {
    return !!this.passwordExpiryService.downstreamAppIdentifier;
  }

}
