<div class="container accessibility-link-group">
  <div class="row">
    <h3 class="col-auto text-ally">{{ pageTitle }}</h3>
    <a class="content-primary link-back col-auto ml-auto" routerLink="/dashboard"> {{ CONSTANTS.backToMyTools }} </a>
  </div>

  <div class="row instruct-centered" *ngIf="isBranchUser">
    <div class="col-md-12"> {{ CONSTANTS.searchInstructions }} </div>
  </div>

  <form [attr.allytmfn]="'contactSearchForm'" *ngIf="isBranchUser" #search="ngForm" name="search" (ngSubmit)="searchByPDN(query, search.valid)"
   class="col-md-6 row">
    <div class="form-group col col-md-8 accessibility-form-input-group">
      <label for="dealership-contact-search-by-pdn">{{ CONSTANTS.pdn }}</label>
      <div class="control-wrapper">
        <div class="input-group">
          <input [attr.allytmfn]="'pdn'" class="form-control col-md-6 col-sm-12" id="dealership-contact-search-by-pdn" [(ngModel)]="query" name="query"
           required pattern="\d{5}|\d{6}" maxlength="6"
           [class.is-invalid]="isSubmitClicked && (search.invalid || isNoDealershipFound)" [attr.allytm-error]="contactsListSearchErrorMessage(search.invalid)">
          <span *ngIf="isSubmitClicked && isPdnEmpty" class="invalid-feedback">
            {{ CONSTANTS.errors.pdnRequired }}
          </span>
          <span *ngIf="isSubmitClicked && (search.invalid || isNoDealershipFound) && !isPdnEmpty" class="invalid-feedback">
              {{ CONSTANTS.errors.pdnNotFound }}
          </span>
        </div>
        <br>
        <button class="btn btn-primary font-weight-bold" type="submit">{{ CONSTANTS.search }}</button>
      </div>
    </div>
  </form>

  <div *ngIf="hasLoaded">
    <div class="row">
        <div class="col-md-12" *ngIf="isBranchUser && dealership">
          <h4 *ngIf="hasLoaded"><b>Contacts for PDN {{ dealership.pdnNumber }} - {{ dealership.dealershipName }}</b></h4>
        </div>
    </div>

    <div class="card" *ngIf="accountExec">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>{{ CONSTANTS.accountExecutive }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <app-contact-detail [contact]="accountExec" layout="horizontal"></app-contact-detail>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="underwritersEmpty">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5> {{ CONSTANTS.underWriting }} </h5>
          </div>
        </div>
        <br>
        <div *ngIf="primaryTeamEmpty">
        <div class="row">
          <div class="col-md-2">
            <h6>
              <b>{{ CONSTANTS.primaryTeam }}</b>
            </h6>
          </div>

          <div class="col-md-10">
            <div class="row">
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[0]]">
                <h6>{{ CONSTANTS.primeTeam }}</h6>
              </div>
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[1]]">
                <h6>{{ CONSTANTS.nonPrimeTeam }}</h6>
              </div>
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[2]]">
                <h6>{{ CONSTANTS.commercialServices }}</h6>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[0]]">
                <app-contact-detail [contact]="underwriters[underwriterRoles[0]]"></app-contact-detail>
              </div>
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[1]]">
                <app-contact-detail [contact]="underwriters[underwriterRoles[1]]"></app-contact-detail>
              </div>
              <div class="col-md-4" *ngIf="underwriters[underwriterRoles[2]]">
                <app-contact-detail [contact]="underwriters[underwriterRoles[2]]"></app-contact-detail>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
      </div>
        <div class="row" *ngIf="underwriters[underwriterRoles[3]]">
          <div class="col-md-2">
            <h6>
              <b>{{ CONSTANTS.managementTeam }}</b>
            </h6>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-4">
                <h6>{{ CONSTANTS.acquisitionManager }}</h6>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-md-4">
                <app-contact-detail [contact]="underwriters[underwriterRoles[3]]"></app-contact-detail>
              </div>
            </div>
          </div>
        </div>
        <!--card body ends here-->
      </div>
      <!--card ends here-->
    </div>

    <!--Dealer Help Desk Card starts here-->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>{{ CONSTANTS.dealerHelpDesk }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-md-4">
                <img class="icon" src="assets/images/Phone_Icon.svg"/>
                <span class="generalContacts">Call</span>
                <p class="text-primary"><a class="helpdesk" [href]="tel('866-766-4622')">866-766-4622</a></p>
              </div>
            </div>
          </div>
        </div>
        <!--card body ends here-->
      </div>
      <!--card ends here-->
    </div>

    <!--Sales Card Starts here -->
    <div class="card" *ngIf="salesLinesEmpty">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>Sales</h5>
          </div>
        </div>
        <div *ngFor="let line of filteredSalesLineKeys">
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleLine(line)">
                <i [className]="lineCaretType(line)">
                  <!--Auto Finance button and chevron glyph-->
                </i>
                <b>{{ CONSTANTS[line] }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="lineVisible[line]">
            <div *ngFor="let team of salesTeamKeys">
              <div *ngIf="salesTeams[line][team]">
                <div class="row">
                  <!--once clicking the chevron and displays downward this information should display-->
                  <div class="col-md-12">
                    <h6>
                      <b> {{ CONSTANTS.classifications[team] }}</b>
                    </h6>
                  </div>
                </div>
                <hr>
                <div *ngFor="let contact of salesTeams[line][team]">
                  <div class="row">
                    <div class="col-md-2">
                      <h6 class="sales"> {{ CONSTANTS.roles[contact.role] }} </h6>
                    </div>
                    <div class="col-md-10">
                      <app-contact-detail [contact]="contact" layout="horizontal"></app-contact-detail>
                      <!--data goes Supporting Team/ Commercial Service here-->
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sales card body ends here-->
    </div>

    <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h5>General Contacts</h5>
            </div>
          </div>
          <div *ngIf='generalContactsHash["Ally Academy"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Ally Academy')">
                <i [className]="generalCaretType('Ally Academy')">
                </i>
                <b>{{ getData("Ally Academy", "title") }}</b>
              </button>
            </div>
          </div>
          <div *ngIf="generalVisible['Ally Academy']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">{{ getData("Ally Academy", "title")}}</p>
                  <p class="gencontact"><a [href]="tel(getData('Ally Academy', 'phoneNumbers')[0].phoneNumber)">
                    {{ getData("Ally Academy", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("Ally Academy", "operationalHours")[0].opnHrs }}</p>
                </div>

                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">Email</span>
                  <p class="text-primary gencontact">
                    <a class="gencontact" [href]="mailto(getData('Ally Academy', 'email'))">
                    {{ getData("Ally Academy", "email") }}
                  </a></p>
                </div>

                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">{{ getData("Ally Academy", "addresses")[0].addressTitle }}</span>
                  <p class="text-primary gencontact">{{ getData("Ally Academy", "addresses")[0].subTitle }}</p>
                  <p class="gencontact">{{ getData("Ally Academy", "addresses")[0].address1 }}
                     {{ getData("Ally Academy", "addresses")[0].address2 }}</p>
                  <p class="gencontact">
                     {{ getData("Ally Academy", "addresses")[0].city }}&nbsp;
                     {{ getData("Ally Academy", "addresses")[0].state }}
                     {{ getData("Ally Academy", "addresses")[0].zipCode }}
                  </p>

                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <br>

        <div *ngIf='generalContactsHash["Ally Servicing"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Ally Servicing')">
                <i [className]="generalCaretType('Ally Servicing')">
                </i>
                <b>{{ getData("Ally Servicing", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Ally Servicing']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-5">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                      <a [href]="tel(getData('Ally Servicing', 'phoneNumbers')[0].phoneNumber)">
                        {{ getData("Ally Servicing", "phoneNumbers")[0].phoneNumber }}
                      </a></p>
                  <p class="gencontact">
                     {{ getData("Ally Servicing", "operationalHours")[0].opnHrs.substring(0, getData("Ally Servicing", "operationalHours")[0].opnHrs.indexOf("^")) }}</p>
                  <p class="gencontact">
                    {{ getData("Ally Servicing", "operationalHours")[0].opnHrs.substring(getData("Ally Servicing", "operationalHours")[0].opnHrs.indexOf("^") + 1) }}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["Commercial Services Group"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Commercial Services Group')">
                <i [className]="generalCaretType('Commercial Services Group')">
                </i>
                <b> {{ getData("Commercial Services Group", "title") }} </b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Commercial Services Group']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-5">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("Commercial Services Group", "phoneNumbers")[0].phoneNumber)'>
                        {{ getData("Commercial Services Group", "phoneNumbers")[0].phoneNumber }}
                    </a></p>
                  <p class="gencontact">{{ getData("Commercial Services Group", "operationalHours")[0].opnHrs }}</p>
                </div>
              </div>
            </div>
          </div>
          <br>
          <hr>

          <div class="row">
            <div class="noPadRight col-md-2">
              <b>{{ getData("Commercial Services Group", "faxNumbers")[0].faxHeader }}</b>
            </div>
            <div class="col-md-10">
              <span class="fax"></span>
              <span class="generalContacts">Fax</span>
              <p class="text-primary">
                <a [href]='tel(getData("Commercial Services Group", "faxNumbers")[0].faxNumber)'>
                {{ getData("Commercial Services Group", "faxNumbers")[0].faxNumber }}
              </a>
              </p>
            </div>
          </div>
          <br>
          <hr>

          <div class="row">
            <div class="col-md-2">
              <b>{{ getData("Commercial Services Group", "faxNumbers")[1].faxHeader }}</b>
            </div>
            <div class="col-md-10">
              <span class="fax"></span>
              <span class="generalContacts">Fax</span>
              <p class="text-primary">
                <a [href]='tel(getData("Commercial Services Group", "faxNumbers")[1].faxNumber)'>
                {{ getData("Commercial Services Group", "faxNumbers")[1].faxNumber }}
              </a></p>
            </div>
          </div>
          <br>
          <hr>
          <div class="row">
            <div class="noPadRight col-md-2">
              <b>{{ getData("Commercial Services Group", "addresses")[0].subTitle }}</b>
            </div>
            <div class="col-md-10">
              <span class="address"></span>
              <span class="generalContacts">Address</span>
              <p>{{ getData("Commercial Services Group", "addresses")[0].address1 }}</p>
              <p>{{ getData("Commercial Services Group", "addresses")[0].city }}&nbsp;
                 {{ getData("Commercial Services Group", "addresses")[0].state }}&nbsp;
                 {{ getData("Commercial Services Group", "addresses")[0].zipCode }}</p>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["Contract Processing Help Desk"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Contract Processing Help Desk')">
                <i [className]="generalCaretType('Contract Processing Help Desk')">
                </i>
                <b>{{ getData("Contract Processing Help Desk", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Contract Processing Help Desk']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("Contract Processing Help Desk", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("Contract Processing Help Desk", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                </div>
                <div class="col-md-4">
                  <span class="fax"></span>
                  <span class="generalContacts">Fax</span>
                  <p class="text-primary">
                    <a [href]='tel(getData("Contract Processing Help Desk", "phoneNumbers")[0].faxVO.faxNumber)'>
                    {{ getData("Contract Processing Help Desk", "phoneNumbers")[0].faxVO.faxNumber }}
                  </a></p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["Dealer Products & Services"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Dealer Products & Services')">
                <i [className]="generalCaretType('Dealer Products & Services')">
                </i>
                <b>{{ getData("Dealer Products & Services", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Dealer Products & Services']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("Dealer Products & Services", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("Dealer Products & Services", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("Dealer Products & Services", "operationalHours")[0].opnHrs }}</p>
                </div>
                <!-- <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">Ally Insurance</span>
                  <p>500 Dearborn Circle, Suite 200</p>
                  <p>Mt.Laurel,NJ 08054</p>
                </div> (data unavailable)-->
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts"> {{ getData("Dealer Products & Services", "addresses")[0].address1 }} </span>
                  <p class="gencontact">
                    {{ getData("Dealer Products & Services", "addresses")[0].address2 }}</p>
                  <p class="gencontact">
                    {{ getData("Dealer Products & Services", "addresses")[0].city }},&nbsp;
                    {{ getData("Dealer Products & Services", "addresses")[0].state }},&nbsp;
                    {{ getData("Dealer Products & Services", "addresses")[0].zipCode }}</p>
                </div>
                <div class="col-md-4">
                  <span class="fax"></span>
                  <span class="generalContacts"> Fax</span>
                  <p class="text-primary">
                    <a [href]='tel(getData("Dealer Products & Services", "faxNumbers")[0].faxNumber)'>
                    {{ getData("Dealer Products & Services", "faxNumbers")[0].faxNumber }}
                  </a></p>
                </div>
                <br>
              </div>
              <br>
              <hr>
            </div>
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-xs-4">
                  <ul style="list-style-type:none">
                    <li>Prompt#</li>
                  </ul>
                  <ul class="number" style="list-style-type:none">
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                  </ul>
                </div>


                <div class="col">
                  <ul style="list-style-type:none">
                    <li>Question</li>
                    <li>Contract Cancellation/Transfers/Address changes or Contracts rejects</li>
                    <li>Claims for a Vehicle Service Contract (VSC)</li>
                    <li>Claims for GAP</li>
                    <li>Billing questions</li>
                    <li>Vehicle Protection Center application access, InteliRater,dealer setup and forms</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["DealerTrack"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('DealerTrack')">
                <i [className]="generalCaretType('DealerTrack')"></i>
                <b>{{ getMappedData("DealerTrack", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['DealerTrack']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("DealerTrack", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("DealerTrack", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">
                    {{ getData("DealerTrack", "operationalHours")[0].opnHrs.substring(0, getData("DealerTrack", "operationalHours")[0].opnHrs.indexOf("^"))}}</p>
                  <p class="gencontact">
                    {{ getData("DealerTrack", "operationalHours")[0].opnHrs.substring(getData("DealerTrack", "operationalHours")[0].opnHrs.indexOf("^") + 1)}}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["National Property Claim Center"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('National Property Claim Center')">
                <i [className]="generalCaretType('National Property Claim Center')">
                </i>
                <b>{{ getData("National Property Claim Center", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['National Property Claim Center']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("National Property Claim Center", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("National Property Claim Center", "phoneNumbers")[0].phoneNumber}}
                  </a></p>
                  <p class="gencontact">{{ getData("National Property Claim Center", "operationalHours")[0].opnHrs}}</p>
                </div>
                <div class="col-md-4">
                  <span class="fax"></span>
                  <span class="generalContacts">Fax</span>
                  <p class="text-primary">
                    <a [href]='tel(getData("National Property Claim Center", "faxNumbers")[0].faxNumber)'>
                    {{ getData("National Property Claim Center", "faxNumbers")[0].faxNumber}}
                  </a></p>
                </div>
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">Email</span>
                  <p class="text-primary gencontact">
                    <a [href]='mailto(getData("National Property Claim Center", "email"))'>
                    {{ getData("National Property Claim Center", "email")}}
                  </a></p>
                </div>
              </div>
            </div>
          </div>
          <br>
          <hr>

          <div class="row">
            <div class="noPadRight col-md-2">
              <b>{{ getData("National Property Claim Center", "addresses")[0].subTitle }}</b>
            </div>
            <div class="col-md-10">
              <span class="address"></span>
              <span class="generalContacts">Address</span>
              <p>{{ getData("National Property Claim Center", "addresses")[0].address2}}</p>
              <p>{{ getData("National Property Claim Center", "addresses")[0].city }}&nbsp;
                {{ getData("National Property Claim Center", "addresses")[0].state }},&nbsp;
                {{ getData("National Property Claim Center", "addresses")[0].zipCode }}
              </p>
            </div>
          </div>
          <br>
          <hr>

          <div class="row">
            <div class="noPadRight col-md-2">
              <b>{{ getData("National Property Claim Center", "addresses")[1].addressTitle }}</b>
            </div>
            <div class="col-md-10">
              <span class="address"></span>
              <span class="generalContacts">Address</span>
              <p>{{ getData("National Property Claim Center", "addresses")[1].subTitle }}</p>
              <p>{{ getData("National Property Claim Center", "addresses")[1].address1 }}</p>
              <p>{{ getData("National Property Claim Center", "addresses")[1].city }}&nbsp;,
                 {{ getData("National Property Claim Center", "addresses")[1].state }},&nbsp;
                 {{ getData("National Property Claim Center", "addresses")[1].zipCode }}
              </p>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["Property & Casualty Policy Administration"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Property & Casualty Policy Administration')">
                <i [className]="generalCaretType('Property & Casualty Policy Administration')">
                </i>
                <b>{{ getData("Property & Casualty Policy Administration", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Property & Casualty Policy Administration']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("Property & Casualty Policy Administration", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("Property & Casualty Policy Administration", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("Property & Casualty Policy Administration", "operationalHours")[0].opnHrs }}</p>
                </div>
                <div class="col-md-4">
                  <span class="fax"></span>
                  <span class="generalContacts">Fax</span>
                  <p class="text-primary">
                    <a [href]='tel(getData("Property & Casualty Policy Administration", "faxNumbers")[0].faxNumber)'>
                    {{ getData("Property & Casualty Policy Administration", "faxNumbers")[0].faxNumber }}
                  </a></p>
                </div>
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">{{ getData("Property & Casualty Policy Administration", "addresses")[0].subTitle }}</span>
                  <p class="gencontact">{{ getData("Property & Casualty Policy Administration", "addresses")[0].address1 }}</p>
                  <p class="gencontact">{{ getData("Property & Casualty Policy Administration", "addresses")[0].city }}&nbsp;
                    {{ getData("Property & Casualty Policy Administration", "addresses")[0].state }}&nbsp;
                    {{ getData("Property & Casualty Policy Administration", "addresses")[0].zipCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["Regional Business Center"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('Regional Business Center')">
                <i [className]="generalCaretType('Regional Business Center')">
                </i>
                <b>{{ getData("Regional Business Center", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['Regional Business Center']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-5">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("Regional Business Center", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("Regional Business Center", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("Regional Business Center", "operationalHours")[0].opnHrs }}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div *ngIf='generalContactsHash["RouteOne"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('RouteOne')">
                <i [className]="generalCaretType('RouteOne')">
                </i>
                <b>{{ getData("RouteOne", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['RouteOne']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("RouteOne", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("RouteOne", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("RouteOne", "operationalHours")[0].opnHrs.substring(0, getData("RouteOne", "operationalHours")[0].opnHrs.indexOf("^")) }}</p>
                  <p class="gencontact">{{ getData("RouteOne", "operationalHours")[0].opnHrs.substring(getData("RouteOne", "operationalHours")[0].opnHrs.indexOf("^") + 1) }}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>


        <div *ngIf='generalContactsHash["SmartAuction Call Center"]'>
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleGC('SmartAuction Call Center')">
                <i [className]="generalCaretType('SmartAuction Call Center')">
                </i>
                <b>{{ getData("SmartAuction Call Center", "title") }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="generalVisible['SmartAuction Call Center']">
          <div class="row">
            <div class="col-md-10 offset-md-2">
              <div class="row">
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Phone_Icon.svg"/>
                  <span class="generalContacts">Call</span>
                  <p class="text-primary gencontact">
                    <a [href]='tel(getData("SmartAuction Call Center", "phoneNumbers")[0].phoneNumber)'>
                    {{ getData("SmartAuction Call Center", "phoneNumbers")[0].phoneNumber }}
                  </a></p>
                  <p class="gencontact">{{ getData("SmartAuction Call Center", "operationalHours")[0].opnHrs }}</p>
                </div>
                <div class="col-md-4">
                  <img class="icon" src="assets/images/Email_Icon.svg"/>
                  <span class="generalContacts">Email</span>
                  <p class="text-primary gencontact">
                    <a [href]='mailto(getData("SmartAuction Call Center", "email"))'>
                    {{ getData("SmartAuction Call Center", "email") }}
                  </a></p>
                </div>
                <div class="col-md-4">
                  <span class="address"></span>
                  <span class="generalContacts"> Address</span>
                  <p>{{ getData("SmartAuction Call Center", "addresses")[0].address1 }}</p>
                  <p>{{ getData("SmartAuction Call Center", "addresses")[0].city }}&nbsp;
                    {{ getData("SmartAuction Call Center", "addresses")[0].state }}&nbsp;
                    {{ getData("SmartAuction Call Center", "addresses")[0].zipCode }}
                  </p>
                </div>

                <div class="col-md-4 SmartAuction">
                  <span class="fax"></span>
                  <span class="generalContacts"> Fax</span>
                  <p class="text-primary">
                    <a [href]='tel(getData("SmartAuction Call Center", "faxNumbers")[0].faxNumber)'>
                    {{ getData("SmartAuction Call Center", "faxNumbers")[0].faxNumber }}
                  </a></p>
                </div>
                <br>
              </div>
              <br>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      </div>
