import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService, HandleError } from './http-error-handler.service';
import { IApplicationDetails } from '../models/application-details.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class OktaListApplicationsService {

  private handleError;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService,
  ) {
    this.handleError = httpErrorHandler.createHandleError('oktaListApplications');
  }

  /**
   * List application details from OKTA based on input parameters
   *
   * @param userId
   * @param applicationName
   */
  public getApplications(userId, applicationName): Observable<{} | IApplicationDetails[]> {
    const options = applicationName ?  { params: new HttpParams().set('application', applicationName) } : {};
    return this.http.get<IApplicationDetails[]>(`${environment.ndaServerURLS.userServices}/user/${userId}/apps`, options)
    .pipe(
      catchError(this.handleError('userAuthentication', []))
    );
  }

}
