import { Injectable, ElementRef, EventEmitter } from "@angular/core";
import { EventTargetInterruptSource, Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { AppConstantsService } from "../app-constants.service";
import CustomDocumentInterruptSource from "./CustomDocumentInterruptSource";

type IdleServiceKey = keyof {
  keepAliveTime: string;
  idleTime: string;
  idleTimeOut: string;
}
namespace IdleServiceKey {
  export const KeepAliveTime: IdleServiceKey = 'keepAliveTime';
  export const IdleTime: IdleServiceKey      = 'idleTime';
  export const IdleTimeOut: IdleServiceKey   = 'idleTimeOut';
}


@Injectable()
export class AppIdleService {

  onInterrupt: EventEmitter<any>         = this.idle.onInterrupt;
  onIdleEnd: EventEmitter<any>           = this.idle.onIdleEnd;
  onTimeout: EventEmitter<number>        = this.idle.onTimeout;
  onIdleStart: EventEmitter<any>         = this.idle.onIdleStart;
  onTimeoutWarning: EventEmitter<number> = this.idle.onTimeoutWarning;
  onPing: EventEmitter<any>              = this.keepalive.onPing;

  get documentInterrupts(){
    return this.idle.getInterrupts().filter((interrupt) => {
      return interrupt.source instanceof EventTargetInterruptSource;
    });
  }

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected appConstants: AppConstantsService,
  ) {
    this.configureServices();
    this.idle.onIdleEnd.subscribe(() => this.watchStart());
    this.setInterruptSource();
  }

  watchToggle(shouldStart: boolean): void {
    shouldStart ? this.watchStart() : this.watchEnd();
  }
  watchStart(): void {
    this.interruptsResume();
    this.idle.watch();
  }
  watchEnd(): void {
    this.idle.stop();
  }

  interruptsPause(): void {
    this.documentInterrupts.forEach((interrupt) => interrupt.pause());
  }
  interruptsResume(): void {
    this.documentInterrupts.forEach((interrupt) => interrupt.resume());
  }

  setInterruptSource(elementForInterrupt?: ElementRef): void {
    const interrupts = [ ...DEFAULT_INTERRUPTSOURCES ];

    if (elementForInterrupt) {
      const interrupt = new CustomDocumentInterruptSource(elementForInterrupt);
      interrupts.push(interrupt);
    }

    this.watchEnd();
    this.idle.setInterrupts(interrupts);
    this.watchStart();
  }

  private configureServices(): void {
    this.keepalive.interval( this.getConfigValueByKey(IdleServiceKey.KeepAliveTime) );
    this.idle.setIdle( this.getConfigValueByKey(IdleServiceKey.IdleTime) );
    this.idle.setTimeout( this.getConfigValueByKey(IdleServiceKey.IdleTimeOut) );
    this.idle.setKeepaliveEnabled(true);
  }

  private getConfigValueByKey(key: IdleServiceKey): number {
    const defaultVal: number = this.appConstants[key];
    const storageVal: number = +localStorage.getItem(key);

    if (storageVal && storageVal <= defaultVal) {
      return storageVal;
    }

    return defaultVal;
  }
}
