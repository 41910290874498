import { Injectable } from '@angular/core';
import { AppConstantsService } from '../services/app-constants.service';
import { Router } from '@angular/router';
import { SessionManagementService } from './session-management.service';

@Injectable()
export class DealerAppInTabService {
  public sessionClearWindowForSA: any;
  constructor(
    private appConstantsService: AppConstantsService,
    private router: Router,
    private sessionManager: SessionManagementService,
  ) { }

  public checkAppliactionToBeOpenedInTab(currentDealerApp: string): boolean {
    if (this.appConstantsService.dealerAppListToBeOpenedInWindow.indexOf(currentDealerApp) >= 0 ){
      return this.openApplicationInTab(currentDealerApp);
    }

    return true;
  }

  public openApplicationInTab(currentDealerApp: string): boolean {
    this.router.navigate([this.appConstantsService.dashboard_path]);
    setTimeout(() => { window.open(`${this.appConstantsService.getAppData(currentDealerApp)['url']}`) }, 500);
    return false;
  }
}
