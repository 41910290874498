import { InjectionToken } from "@angular/core";

export let PageService = new InjectionToken('lpTag')

export function PageServiceFactory() {
    return window['lpTag'];
}

export const PAGE_SERVICE_PROVIDER = [
    { provide: PageService, useFactory: PageServiceFactory },
];