import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  AfterContentInit, ChangeDetectorRef, NgZone
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { OtpService } from '../../services/otp/otp.service'
import { StoreService } from '../../../services/store.service';
import { UserModel } from '../../../models/user-model';
import { loginModel } from '../../../models/login.Model';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';
import { otpModel } from '../../../models/otp-model';
import { MaskEmailPipe } from '../../pipes/mask-email.pipe';
import { MaskPhonePipe } from '../../pipes/mask-phone.pipe';
import { IFlowMap } from '../../../models/flow-map.interface';
import { AuthenticationService } from '../../../services/authentication.service';
import { BookmarkingService } from '../../../services/bookmarking.service';
import { environment } from '../../../../environments/environment';
import { ISessionDetails } from '../../../models/session-details.interface';
import { extend, find, first, filter, pick, reject, template, cloneDeep, get, includes } from 'lodash';
import { IFactor } from '../../models/factor.interface';
import { Observable } from 'rxjs/Observable';
import { IProfile } from '../../../models/profile.interface';
import { SpinnerService } from '../../../loading-spinner/services/spinner/spinner.service';
import { PasswordWarnComponent } from '../../../components/password-warn/password-warn.component';
import { PasswordStatus, PasswordExpiryService } from '../../../services/password-expiry.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PreLoginUserDetailsService } from '../../../change-password-pre-login/services/pre-login-user-details.service';
import { SessionManagementService } from '../../../services/session-management.service';
import {IUserProfile} from "@nda/app/profile/models/profile.interface";

const FactorStatus = [ 'ACTIVE' , null, ];

export type CookieNameOtp = keyof {
  deviceToken
};
export namespace CookieNameOtp {
  export const deviceToken: CookieNameOtp = "deviceToken";
}

export type OtpContent = {
  userModel: UserModel;
  credentials?: loginModel;
}


@Component({
  selector: 'modal-otp[flowMap]',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.scss'],
  providers: [MaskEmailPipe, MaskPhonePipe]
})

export class ModalOtpComponent implements AfterViewInit {
  readonly EMAIL = 'email';
  readonly SMS = 'sms';
  readonly PENDING_ACTIVATION = 'PENDING_ACTIVATION';
  readonly NOT_SELECTED = 'NOT_SELECTED';

  private hasPendingRequest: boolean = false;
  private _userModel;
  private credentials: loginModel;

  public appStrings: { [key: string]: any } = AppStrings;
  public showEmailId: string;
  public showEmailFactorType: string;
  public userMobileNumber: string;
  public SMSFactorType: string;
  public isSendOtpScreen: boolean;
  public isModalLoaded: boolean = false;
  public isEditProfileFlow: boolean;
  private type = '';
  private path = '';
  private headers = '';
  public otpModel: otpModel;
  public otpPattern = '^[0-9]*$';
  public isSubmitClicked = false;
  public isSendClicked = false;
  public responseQry = [];
  public sendOtpErr = [];
  public factors = [];
  public factorAdded;
  private _factorChoice: string = this.NOT_SELECTED;
  private redirectPath: string;
  public isEditProfileActive: boolean = false;
  private timedOut = false;
  private timedOutTimeout: number;
  public readonly CONSTANTS = {
    ...AppStrings['common'],
    'authErrors': AppStrings['authErrors']
  };

  @Input('flowMap') flowMap: IFlowMap;
  @Input('downstreamAppIdentifier') downstreamAppIdentifier: string;

  @Output() isComplete = new EventEmitter();
  @Output() onHidden   = new EventEmitter();

  @ViewChild('ndaModal', { static: false }) ndaModal: ModalDirective;
  @ViewChild('pwdWarnModel', { static: false  }) pwdWarnModel:PasswordWarnComponent;
  @ViewChild('otpValSecCode', { static: false  }) otpValSecCode: any;
  @ViewChild('otpCode', { static: false  }) otpCode: ElementRef;

  constructor(public maskEmail: MaskEmailPipe,
    private otpService: OtpService,
    private storeService: StoreService,
    private router: Router,
    private appConstantsService: AppConstantsService,
    private authenticationService: AuthenticationService,
    private bookmarkingService: BookmarkingService,
    private cookieService: CookieService,
    private sessionManager: SessionManagementService,
    private spinnerService: SpinnerService,
    private passwordService : PasswordExpiryService,
    private preLoginUserDetailsService: PreLoginUserDetailsService,
    private cdRef: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.userModel = {
      resource:   null,
      exceptions: null
    };
    this.otpModel = {
      otpNumber: null,
    };
  }

  get userModel() {
    return this._userModel.resource;
  }
  set userModel(model) {
    this._userModel = model;
  }

  get userId(): string {
    return this.userModel.sid || this.userModel.userId || null;
  }

  get userName(): string {
    if(this.userModel.profile === undefined){
      return this.sessionManager.userName;
    }
    return this.userModel.profile.login;
  }

  get stateToken(): string {
    return this.userModel.stateToken || null;
  }

  get isRegistration(): boolean {
    return this.flowMap.trigger == this.appConstantsService.otp_registration_flow.trigger;
  }
  get isLogin(): boolean {
    return this.flowMap.trigger == this.appConstantsService.otp_login_flow.trigger;
  }
  get isForgotPassword(): boolean {
    return this.flowMap.trigger == this.appConstantsService.otp_forgotPwd_flow.trigger;
  }

  get isEditProfile(): boolean {
    if (!!this.flowMap) {
     return this.flowMap.trigger == this.appConstantsService.edit_profile_flow.trigger;
    }
    return false;
  }

  get hasDefaultSelection(): boolean {
    return this.isRegistration || this.isEditProfile;
  }
  get isDefaultSelectionSMS(): boolean {
    return this.isRegistration && this.showSMSFactor;
  }
  get isDefaultSelectionEmail(): boolean {
    return this.isRegistration && !this.showSMSFactor;
  }

  get factorChoice(): string {
    if (this.isRegistration) {
      return this.isDefaultSelectionSMS ? this.smsFactorId : this.emailFactorId;
    } else if (this.isEditProfile) {
      return this.smsFactorId;
    }

    return this._factorChoice;
  }
  set factorChoice(factorChoice: string) {
    this._factorChoice = factorChoice;
  }

  get hasMultipleFactors(): boolean {
    return this.factors.length > 1;
  }

  get shouldDisplaySendAlternateCode(): boolean {
    let hasAlertnateFactors = this.hasMultipleFactors || !!this.userMobileNumber && this.canResendOTP;
    return this.isEditProfile ? false : (!this.isRegistration && hasAlertnateFactors);
  }

  get factorSelected() {
    if (this.factorChoice == this.NOT_SELECTED) { // ಠ_ಠ this is gross
      return find(this.factors, ['factorType', this.EMAIL]);
    } else if (this.isEditProfile) {
      return this.factors[0];
    }
    return first(filter(this.factors, ['id', this.factorChoice])) || this.factorAdded; // ಠ_ಠ this is gross
  }
  get factorAlternative() {
    return first(reject(this.factors, ['id', this.factorChoice])) || this.factorAdded; // ಠ_ಠ this is gross;
  }

  get isFactorSelectedEmail(): boolean {
    return this.factorSelected.factorType === this.EMAIL;
  }
  get isFactorSelectedSMS(): boolean {
    return this.factorSelected.factorType === this.SMS;
  }

  get displayOtpSendAlternateCode(): string {
    const STRINGS = this.appStrings.modalOtp;
    let method = this.isFactorSelectedEmail ? STRINGS.smsFactor : STRINGS.emailFactor;
    method = method.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    return template(STRINGS.otpSendAlternateCode)({ method });
  }

  public get showSMSFactor(): boolean {
    return this.userMobileNumber && this.userMobileNumber !== '';
  }

  public get showSendError(): boolean {
    return this.isSendClicked && this.factorChoice === this.NOT_SELECTED;
  }

  public get emailFactorId(): string {
    return this.factors.filter(factor => factor.factorType === this.EMAIL)[0].id;
  }

  public get hasOtpSubmissionException() {
    return this.responseQry.length > 0;
  }

  public get smsFactorId(): string {
    if (this.factors.find(factor => factor.factorType === this.SMS)) {
      return this.factors.find(factor => factor.factorType === this.SMS).id;
    }

    return '';
  }

  ngAfterViewInit() {
    this.ndaModal.onHidden.subscribe(() => this.onHidden.emit());
  }

  redirectToDownstreamLogin(): void {
    if (this.downstreamAppIdentifier) {
      window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['loginPageURL']);
    }
  }

  get canResendOTP(): boolean {
    return !!get(this, 'credentials.pwd') || !this.timedOut;
  }

  public hide(): void {
    this.sendOtpErr = [];
    this.otpModel.otpNumber = null;
    if (this.downstreamAppIdentifier) {
      this.redirectToDownstreamLogin();
    }
    else {
      this.ndaModal.hide();
    }

    if(this.spinnerService.isVisible){
      this.spinnerService.hide();
    }
  }

  public sendOtp(flow?: IFlowMap ): void {
    this.isSendClicked = true;

    if (this.showSMSFactor && this.factorChoice === this.NOT_SELECTED) {
      return;
    }

    if (!this.shouldEnrollFactor()) {
      return this.sendOTPToEnrolledFactor();
    }
    this.enrollFactorInOTP().subscribe((res)=>{
      this.factorChoice = res.id;
      res['profile'] = { phoneNumber: this.userMobileNumber };
      this.setFactorInformation([res]);
      this.isSendOtpScreen = false;
      this.cdRef.detectChanges();
    });

  }

  sendOtpAlternate(): void {
    if (this.factorAlternative) {
      this.factorChoice = this.factorAlternative.id;
      this.sendOtp();
    }
    else {
      this.enrollFactorInOTP().subscribe(() => {
        this.factorChoice = this.factorAlternative.id;
      });
    }
  }

  public submitOtp(): void {
    this.spinnerService.show();

    this.otpService.submitOtp(
      this.stateToken,
      this.userId,
      this.userName,
      this.factorSelected['id'],
      this.otpModel.otpNumber,
    )
      .subscribe(
        response => this.handleSubmitOtpSuccess(response),
        err => this.handleSubmitOtpFailure(err)
      );
  }

  private handleSubmitOtpSuccess(response) {
    if (response['resource']) {
      window.clearTimeout(this.timedOutTimeout);
      this.storeService.write('isEditProfileOtpVerified', true);

      let date = new Date();
      date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));

      // app-guard.canActivate will call spinnerService.hide()

      if (this.isEditProfileFlow) {
        this.storeService.write("isEditProfileOtpVerified", true);
      }
      if (this.flowMap && (this.flowMap.trigger === 'login' || this.flowMap.trigger === 'resitration')) {
        if (response['resource']['sessionToken'] && response['resource']['sessionId']) {
          this.sessionManager.update('sessionToken', response['resource']['sessionToken']);
          this.sessionManager.update('sessionId', response['resource']['sessionId']);
        }
      }
      if (this.flowMap && this.flowMap.trigger === 'forgotPwd') {
        this.sessionManager.update('profile', <IProfile>{ userId: this.userId });
      }

      this.ndaModal.hide();
      this.isComplete.emit();

      // Storing App identifier in service for Password Expire and Password warn downstream navigation
      if (this.isUserLoggedInFromDownStream) {
        this.passwordService.downstreamAppIdentifier = this.downstreamAppIdentifier;
      }

      // Storing status and password changed date if present to show ABOUT TO EXPIRE modal in case of PASSWORD_WARN status
      this.passwordService.status = response['resource']['status'];
      this.passwordService.passwordExpiryDate = get(response, 'resource.passwordExpiredDate');

      // Condition to check for PASSWORD_EXPIRED status and force navigate to Change Password screen
      if (response['resource']['status'] === PasswordStatus.PasswordExpired) {
        const userProfile = cloneDeep(response['resource'].profile);
        userProfile.userId = response['resource'].sid;
        this.preLoginUserDetailsService.profile = userProfile;
        this.spinnerService.hide();
        this.zone.run(()=>{
          this.router.navigate([this.appConstantsService.pre_login_change_password_path]);
        })
        this.spinnerService.hide();
      } // condition to split the navigation flow for downstream application and NDA
      else if (this.isUserLoggedInFromDownStream) {
        this.spinnerService.hide();
        if (response['resource']['status'] && response['resource']['status'] != PasswordStatus.PasswordWarn) {
          let sessionToken = this.sessionManager.sessionToken;
          window.location.assign(environment.downstreamAppURL[this.downstreamAppIdentifier]['landingPageURL'] + sessionToken);
        }
      }
      else if (this.flowMap && this.flowMap['routeTo'] && this.flowMap.trigger === 'login') {

        this.redirectPath = this.bookmarkingService.getBookmarkedUrl();
        this.bookmarkingService.resetBookmarkedUrl();
        this.zone.run(()=>{
          this.router.navigate([this.redirectPath]);
        })
      } else if (this.flowMap && this.flowMap['routeTo'] && this.flowMap.trigger !== 'resitration') {
        this.zone.run(()=>{
          this.router.navigate([this.flowMap['routeTo']]);
        })
      } else {
        this.spinnerService.hide();
      }
    }
  }

  private handleSubmitOtpFailure(response): void {
    let errorMessage: string;

    // The server returns a 400 on submission failure for SMS enrollment during registration.
    if (get(response, 'status') === 400) {
      errorMessage = get(this.CONSTANTS['authErrors'], this.timedOut ? 'ERR_000003' : 'ERR_000008');
    }
    else if (get(response, 'status') === 403) {
      errorMessage = get(this.CONSTANTS, 'authErrors.ERR_000008');
    }
    else if (get(response, 'error.exceptions')) {
      const exception = response['error']['exceptions'];
      errorMessage = get(this.CONSTANTS['authErrors'], exception.code) || exception.message;
    }
    setTimeout(()=>{this.cdRef.detectChanges();},500)
    this.responseQry = [ errorMessage ];
    this.spinnerService.hide();
  }

  private sendOTPToEnrolledFactor() {
    const factorId = this.findFactorId();

    window.clearTimeout(this.timedOutTimeout);
    this.timedOutTimeout = window.setTimeout(() => {
      this.timedOut = true;
      this.redirectToDownstreamLogin();
    }, 5 * 60 * 1000);

    this.otpService.sendOtp(this.stateToken, this.userId, factorId)
      .subscribe((response: Response) => {
        if (response === null) {
          this.showEnterOtpCodeView();
          this.isModalLoaded = true;
          this.isSendOtpScreen = true;
          this.isSendOtpScreen = false;
          this.cdRef.detectChanges();
          // $$el.context.factorChoice = $$el.context.factors[0].id
        }
        else if (get(response, 'error.exceptions')) {
          this.sendOtpErr = [ response['error']['exceptions'].message ];
        }
      },
        (error: Response) => {
          if (error.status === 401) {
            this.authenticationService.getUserDetails(this.credentials.userId, this.credentials.pwd)
            .do(response => {
              this.userModel.stateToken = response['resource'].stateToken;
              this.isSubmitClicked = false;
              this.responseQry = [];
              this.sendOtpErr = [];
              this.otpModel.otpNumber = null;
              this.otpValSecCode.form.markAsPristine();
            })
            .subscribe(
              () => this.sendOTPToEnrolledFactor(),
              (err: Response) => {
                if (err.status === 422) {
                  this.hide();
                }
              }
            );
          }
        });
  }

  private findFactorId() {
    if (this.factors.length === 1 && (this.userMobileNumber === undefined || this.userMobileNumber === '')) {
      return this.factors[0].id;
    }

    return this.factorChoice;
  }

  private enrollFactorInOTP(): Observable<IFactor> {
    this.isEditProfileActive = false;

    window.clearTimeout(this.timedOutTimeout);
    this.timedOutTimeout = window.setTimeout(() => {
      this.timedOut = true;
    }, 5 * 60 * 1000);

    return this.authenticationService.createSMSFactor(this.userId, this.userMobileNumber).map(factor => {
      this.showEnterOtpCodeView();
      this.isModalLoaded = true;
      let profile = { phoneNumber: factor['phoneNumber'] };
      let factorOption = extend({}, pick(factor, 'id', 'factorType'), { profile });
      this.factorAdded = factorOption;
      return factor;

    });
  }

  public enterOtpNo(event): void {
    this.responseQry = [];
    const otpPatternKeyStroke = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if ([0, 8].indexOf(event.charCode) !== -1) return;

    if (event.charCode !== 118 && !otpPatternKeyStroke.test(inputChar)) {
      event.preventDefault();
    }
  }

  public otpSubmit(): void {
    this.isSubmitClicked = true;
  }

  public show(content: OtpContent,flow?: IFlowMap): void {
    this.credentials = content.credentials;
    this.userModel = content.userModel;
    if(flow){this.flowMap = flow}
    this.setFactorInformation(this.userModel.factors);

    if (this.userModel.profile.mobilePhone) {
      this.userMobileNumber = this.userModel.profile.mobilePhone;
      this.SMSFactorType = this.SMS;
    }

    this.isSendOtpScreen = true;
    this.isModalLoaded = true;
    setTimeout(()=>{
      this.cdRef.detectChanges();
      this.ndaModal.show();
    },500);
  }
  public showOTP(content: OtpContent): void {
    this.credentials = content.credentials;
    this.userModel = content.userModel;


    this.isModalLoaded = true;
    setTimeout(()=>{
      this.cdRef.detectChanges();
      this.ndaModal.show();
    },900);
  }

  setFactorInformation(factors): void {
    this.factors = factors;
    factors.map(factor => {
      this.setEmailFactorInformation(factor);
      this.setSMSFactorInformation(factor);
    });
  }

  private setEmailFactorInformation(factor): void {
    if (factor.factorType === this.EMAIL && factor.profile.email) {
      this.showEmailId = factor.profile.email;
      this.showEmailFactorType = factor.factorType;
    }
  }

  private setSMSFactorInformation(factor): void {
    if (factor.factorType === this.SMS && factor.profile.phoneNumber) {
      this.userMobileNumber = factor.profile.phoneNumber;
      this.SMSFactorType = factor.factorType;
    }
  }


  public showMobileOtp(mobileNumber: string, userId: string, user: IUserProfile, userOldInfo: IUserProfile): void {
    this.userMobileNumber = !!mobileNumber ? mobileNumber : null;
    this.userModel = {
      resource:   user,
      exceptions: null
    };
    this.userModel['stateToken'] = this.sessionManager.sessionToken;
    this.userModel['userId'] = userId;
    this.userModel['login'] = this.sessionManager.userName;
    this.isEditProfileActive = false;
    this.storeService.write("isEditProfileOtpVerified", false);
    this.isEditProfileActive = true;

    if((!!this.userMobileNumber && !!userOldInfo.cellPhone) ){
      this.createSMSFactor(this.userMobileNumber);
    }
    else if((!!this.userMobileNumber && !!!userOldInfo.cellPhone) ){
      this.createSMSFactor(this.userMobileNumber);
    }
    else if((!!!this.userMobileNumber && !!userOldInfo.cellPhone) ){
      this.createSMSFactor(this.userMobileNumber);
    }
    else{
      this.storeService.write("isEditProfileOtpVerified", true);
      setTimeout(()=>{
        this.isComplete.emit();
        this.cdRef.detectChanges();
      },500)

    }

  }

  showEnterOtpCodeView(): void {
    setTimeout(() => {
      this.isSendOtpScreen = false;
      this.ndaModal.show();
      this.isModalLoaded = true;
      this.otpCode && this.otpCode.nativeElement.focus();
      this.cdRef.detectChanges();
    },900);
  }

  private shouldEnrollFactor(): boolean {
    if (this.factors.length === 1 && (this.userMobileNumber === undefined || this.userMobileNumber === '')) {
      return false;
    }

    const factor = this.findFactorById(this.factorChoice);

    if (this.factorChoice === undefined || this.factorChoice === '' || (factor && factor.status && factor.status === this.PENDING_ACTIVATION)) {
      return true;
    }

    return false;
  }

  private findFactorById(factorId: string) {
    return this.factors.find(factor => factor.id === factorId);
  }

  public get isUserLoggedInFromDownStream() : boolean {
    return this.downstreamAppIdentifier && this.downstreamAppIdentifier !== this.appConstantsService.storefrontAppIdentifier;
  }

  private createSMSFactor(mobileNumber) {
    this.authenticationService.createSMSFactor(this.userId, this.userMobileNumber).subscribe(factor => {
      this.factorChoice = factor.id;
      factor['profile'] = { phoneNumber: mobileNumber != null ? mobileNumber : '0000'  };
      this.setFactorInformation([factor]);

      this.flowMap = this.appConstantsService.edit_profile_flow;
      this.flowMap.trigger = this.appConstantsService.edit_profile_flow.trigger;
      this.isModalLoaded = true;
      let profile = { phoneNumber: factor['phoneNumber'] };
      let factorOption = extend({}, pick(factor, 'id', 'factorType'), { profile });
      this.factorAdded = factorOption;
      if(factor.status === 'ACTIVE'){
        this.storeService.write('isEditProfileOtpVerified',true);
        this.isComplete.emit();
        return
      }
      else if(mobileNumber != null){
        this.sendOTPToEnrolledFactor();
        this.showEnterOtpCodeView();
      }
      else{
        this.storeService.write('isEditProfileOtpVerified',true);
        this.isComplete.emit();
      }

      this.cdRef.detectChanges();

    },error =>{
      console.log(error)
      if(error.error.code == 'E0000001' && this.userMobileNumber === null){
        this.storeService.write('isEditProfileOtpVerified',true);
        this.isComplete.emit();
      }
    });
  }
}
