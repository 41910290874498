import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { SessionHeaderComponent } from './components/session-header/session-header.component';
import { ModalSessionTimeoutComponent } from './components/modal-session-timeout/modal-session-timeout.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PopoverModule.forRoot()
  ],
  declarations: [
    SessionHeaderComponent,
    ModalSessionTimeoutComponent,
  ],
  entryComponents: [
    SessionHeaderComponent,
    ModalSessionTimeoutComponent,
  ],
  exports: [
    SessionHeaderComponent,
    ModalSessionTimeoutComponent,
  ]
})
export class SessionModule { }
