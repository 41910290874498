import {Injectable, NgZone} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as OktaAuth from '@okta/okta-auth-js';
import {Observable} from 'rxjs/Observable';
import { assign, template } from 'lodash';
import { environment } from '@nda/environments/environment';
import { Observer } from 'rxjs';
import { dec2hex, generatePKCECodes } from './pkce-utils';
import 'rxjs/add/operator/mergeMap'
import {Router} from "@angular/router";
import {StoreService} from "@nda/app/services";

export interface OktaToken {
  accessToken: string;
  authorizeUrl: string;
  expiresAt: number;
  scopes: string | string[];
  tokenType: string;
  userinfoUrl: string;
}

export interface IOidcClientConfig {
  clientId: string;
  scopes: string | string[];
  responseType?: string;
  sessionToken?: string;
}

export type OidcClientConfigValue = keyof {
  token,
  id_token,
}
export namespace OidcClientConfigValue {
  export const idToken: OidcClientConfigValue = "id_token";
  export const accessToken: OidcClientConfigValue = "token";
}

@Injectable()
export class OktaAuthService {

  authClient;
  private token: OktaToken;

  constructor(private http: HttpClient,
              private zone: NgZone,
              private storeService: StoreService,
              private router:Router) {
    const isExternal = location.origin.indexOf('int') < 0;
    const issuerId   = environment.oktaOAuth.issuerId[isExternal ? 'external' : 'internal'];
    const configUrls = environment.oktaOAuth.clientConfigUrls;

    let config = assign({}, configUrls, {
      redirectUri:  `${location.origin}/ui`,
      clientId:     environment.oktaOAuth.clientId,
      issuer:       template(<any>configUrls.issuer)({ issuerId }),
      authorizeUrl: template(<any>configUrls.authorizeUrl)({ issuerId })
    });

    this.authClient = new OktaAuth(config);
  }

  generateFingerprint(): Observable<string> {
    return Observable.create((observer: Observer<any>) => {
      this.authClient.fingerprint()
        .then(fingerprint => {
          observer.next(fingerprint);
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
          observer.complete();
        });
    });
  }

  requestToken(config: IOidcClientConfig): Promise<OktaToken> {
    const DEFAULTS = { responseType: OidcClientConfigValue.accessToken };
    config = assign(DEFAULTS, config);

    return this.authClient.token.getWithoutPrompt(config)
      .then((token: OktaToken) => this.token = token);
  }

  requestSession(): Promise<any> {
    return this.authClient.session.get();
  }

  requestTokenDetails(token: string): Observable<any> {
    const url = `${environment.ndaServerURLS.userServices}/authorize-user`;

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(url, { headers });
  }

  /*  This function is used to concatanate the url/endpoint for the Apigee Authorize Redirect */
  createApigeeAuthorizeUrl(code_challenge: any): any {
    const isExternal = location.origin.indexOf('int') < 0;
    const extIntDomainurl   = environment.apigeeAuthorize.redirectUri[isExternal ? 'external' : 'internal'];
      return (
          environment.apigeeAuthorize.baseUrl +
          '?client_id=' +
          environment.apigeeAuthorize.clientSecret +
          '&redirect_uri=' +
          extIntDomainurl+
          '&response_type=code' +
          '&scope=alertTriggers' +
          '&state=5SnnWYAcHjiJ' +
          '&code_challenge=' +
          code_challenge +
          '&code_challenge_method=S256' +
          '&app_name=' +
          environment.apigeeAuthorize.appName
      );
  };

  /*  This function generates the PKCE codes and then calls the Apigee Authorize Redirect */
  ApigeeRedirect = () => {
    generatePKCECodes().then((res: any) => {
      let redirectUrl = this.createApigeeAuthorizeUrl(res);
      window.location.replace(redirectUrl);
    });
  };

  /*  This function calls the dealer/oauth2/token api call to get a JWT bearer token from Apigee  */
  getApigeeToken() {

    const url = environment.apigeeToken.baseUrl;
    let tokenReq = this.processAccessToken();
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    return new Promise((resolve, reject) => {
      this.http.post(url, tokenReq, headers)
        .subscribe(data => {
          if(typeof(data) === 'object') localStorage.setItem('proxyLayerObject', JSON.stringify(data));
          resolve(data)
        }, (error) => {
          reject(error)
        })
    })
  };

  /*  This function generates the body for the Apigee Token call  */
  processAccessToken(){

    let codeVerifier = sessionStorage.getItem('verifier');
    let apigeeJwtAccessToken = JSON.parse(localStorage.getItem('proxyLayerObject'));
    let grantType = `authorization_code`;
    let refreshParam = '';
    const isExternal = location.origin.indexOf('int') < 0;
    const extIntDomainurl   = environment.apigeeAuthorize.redirectUri[isExternal ? 'external' : 'internal'];

    if(!!apigeeJwtAccessToken && typeof(apigeeJwtAccessToken) === 'object'){
      grantType = 'refresh_token';
      refreshParam = '&refresh_token=' + apigeeJwtAccessToken['refresh_token'];
    }

    return 'grant_type=' + grantType
    + '&client_id=' + environment.apigeeAuthorize.clientSecret
    + '&code=' + sessionStorage.getItem('authCode') + '&code_verifier='
    + codeVerifier + '&redirect_uri='
    + extIntDomainurl
    + refreshParam;
  };

  refreshToken(): Promise<any> {
    return this.authClient.token.refresh(this.token)
      .then(token => this.token = token);
  }

  clearToken(): void {
    this.token = null;
  }

  setApigeeJwt(jwtResponse: any): void {
    localStorage.setItem('proxyLayerObject', JSON.stringify(jwtResponse))
  }

  getApigeeJwt(): string {
    return localStorage.getItem('proxyLayerObject');
  }

  clearApigeeJwt(): void {
    localStorage.removeItem('proxyLayerObject');
  }

  // Returns the current Apigee access token ready to be passed in headers.
  get apigeeAccessToken(): string {
    return `Bearer ${JSON.parse(localStorage.getItem('proxyLayerObject'))['access_token']}`;
  }

  signout(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.authClient.signOut()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(() => {
          observer.next(false);
          observer.complete();
        });
    });
  }

  authorize(config: IOidcClientConfig): Promise<any> {
    return new Promise((resolve, reject) => {

      this.requestToken(config)
        .then((token: OktaToken) => this.requestTokenDetails(token.accessToken))
        .then(request => request.subscribe(data => resolve(data), () => reject()))
        .catch(err => reject(err));
    });
  }
}
