import { Component, OnInit, Input } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';

@Component({
  selector: 'app-password-complexity',
  templateUrl: './password-complexity.component.html',
  styleUrls: ['./password-complexity.component.scss']
})
export class PasswordComplexityComponent implements OnInit {

  public readonly CONSTANTS = {
    ...AppStrings['registration']['passwordComplexity']
  };

  @Input('password')
  password = '';

  constructor() { }

  ngOnInit() {    
  }

  get isValidNumberOfCharacters(){
    return this.password.length >= 8 && this.password.length <= 20;
  }

  get hasUppercaseCharacters(){
    return /(?=.*[A-Z])/.test(this.password);
  }

  get hasLowercaseCharacters(){
    return /(?=.*[a-z])/.test(this.password);
  }

  get hasNumber(){
    return /(?=.*\d)/.test(this.password);
  }
}
