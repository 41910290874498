import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPhone'
})
export class MaskPhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const lastFour = value.slice(-4);
    return 'xxx-xxx-' + lastFour;
  }

}
