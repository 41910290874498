import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';
import { PasswordExpiryService } from '../../../services/password-expiry.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'modal-password-changed',
  templateUrl: './modal-password-changed.component.html',
  styleUrls: ['./modal-password-changed.component.scss']
})
export class ModalPasswordChangedComponent {

  public readonly CONSTANTS = AppStrings['confirmPassword'];

  @ViewChild('passwordChanged', { static: true }) passwordChanged: ModalDirective;

  constructor(
    private router: Router,
    private appConstantsService: AppConstantsService,
    private passwordExpiryService : PasswordExpiryService
  ) { }

  public show(){
    this.passwordChanged.show();
  }

  public hide(){
    this.passwordChanged.hide();
    if (this.isDownStreamFlow) {
      return window.location.assign(environment.downstreamAppURL[this.passwordExpiryService.downstreamAppIdentifier]['loginPageURL']);
    }
    this.router.navigate([this.appConstantsService.login_path]);
  }

  public get isDownStreamFlow() {
    return !!this.passwordExpiryService.downstreamAppIdentifier;
  }
}
