import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';

@Component({
    selector: 'modal-error-forgot-password',
    templateUrl: './modal-error-forgot-password.component.html',
    styleUrls: ['./modal-error-forgot-password.component.scss']
  })
  export class ModalErrorForgotPasswordComponent implements OnInit {
    public readonly CONSTANTS = AppStrings['errorForgotPassword'];

    modalRef: BsModalRef;

    constructor(
      private router: Router,
      private appConstantsService: AppConstantsService, 
      private modalService: BsModalService ) { }
  
    ngOnInit() {
    }
  
    public hide(){
        this.modalService.hide(1);
    }
  }
  