export class NavigatorDetails {

  static get isOnline(): boolean {
    return navigator.onLine;
  }
  static get isOffline(): boolean {
    return !NavigatorDetails.isOnline;
  }

  static get agent(): string {
    return navigator.userAgent;
  }

  static get versionIE(): number {
    const agent = NavigatorDetails.agent;

    let msie = agent.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(agent.substring(msie + 5, agent.indexOf('.', msie)), 10);
    }

    let trident = agent.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      let rv = agent.indexOf('rv:');
      return parseInt(agent.substring(rv + 3, agent.indexOf('.', rv)), 10);
    }

    let edge = agent.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(agent.substring(edge + 5, agent.indexOf('.', edge)), 10);
    }

    // other browser
    return null;
  }

  static get clientBrowser(): string {
    const ieVersion = NavigatorDetails.versionIE;
    if      (ieVersion && ieVersion <  12) return `ie-${ieVersion}`;
    else if (ieVersion && ieVersion >= 12) return `edge-${ieVersion}`;

    else if (this.agent.indexOf('Opera')   != -1) return 'opera';
    else if (this.agent.indexOf('Chrome')  != -1) return 'chrome';
    else if (this.agent.indexOf('Safari')  != -1) return 'safari';
    else if (this.agent.indexOf('Firefox') != -1) return 'firefox';

    else return navigator.appName;
  }
}
