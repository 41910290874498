import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import { Router } from '@angular/router';

import { ContactsFlyoutComponent } from '../../contacts/components/contacts-flyout/contacts-flyout.component';
import { SessionManagementService } from '../../services/session-management.service';
@Component({
  selector: 'nda-nav',
  templateUrl: './nda-nav.component.html',
  styleUrls: ['./nda-nav.component.scss']
})
export class NdaNavComponent implements OnInit {

  readonly CONSTANTS = {
    ...AppStrings['common'],
    ...AppStrings['header']
  };

  @Output() showFlyout = new EventEmitter<boolean>();
  @Input() isFlyoutShowing: boolean;

  constructor(private router: Router,
    private sessionManager: SessionManagementService,
  ) { }

  ngOnInit() { }

  contacts() {
    this.isFlyoutShowing = !this.isFlyoutShowing;

    if (this.sessionManager.isBranchUser) {
      this.router.navigate(['/contacts']);
    }
    else {
      this.showFlyout.emit(this.isFlyoutShowing);
    }
  }

  logout() {
    this.router.navigate(['logout']);
  }

  get isApprovedUser(): boolean {
    return this.sessionManager.isBranchUser || this.sessionManager.hasBirthright;
  }

  get shouldDisplayChat(): boolean {
    return this.sessionManager.hasSession && !!this.sessionManager.profile;
  }
}
