import { Injectable } from '@angular/core';

export type BookmarkURL = keyof {
  dashboard
}

export namespace BookmarkURL {
  export const dashboard: BookmarkURL = "dashboard";
}

@Injectable()
export class BookmarkingService {
  private defaultUrl: string = BookmarkURL.dashboard;
  private redirectUrl: string = this.defaultUrl;

  constructor() { }

  public getBookmarkedUrl(): string {
    if (!!sessionStorage.getItem('redirectUrl')) {
      this.redirectUrl = sessionStorage.getItem('redirectUrl');
    }

    return this.redirectUrl;
  }

  public setBookmarkedUrl(url: string): void {
    sessionStorage.setItem('redirectUrl', url);
    this.redirectUrl = url;
  }

  public resetBookmarkedUrl(): void {
    this.redirectUrl = this.defaultUrl;
  }

}
