<ul class="nav nav-pills pull-right nda-top-nav">
    <li *ngIf="shouldDisplayChat" role="presentation">
        <app-nda-chat></app-nda-chat>
    </li>
    <li *ngIf="isApprovedUser" role="presentation">
        <button class="btn btn-link font-weight-bold" (click)="contacts()">{{CONSTANTS.contacts}}</button>
    </li>
    <!-- <li role="presentation"><a href="#">Help</a></li> -->
    <li *ngIf="isApprovedUser" role="presentation">
        <a class="btn btn-link font-weight-bold" tabindex="0" (click)="logout()">{{CONSTANTS.logout}}</a>
    </li>
</ul>