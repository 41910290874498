import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from './guards/app.guard';
import { RegisterGuard } from './registration/guards/register.guard';
import { ManageUsersGuard } from './guards';
import { UserDetailsGuard } from './change-password-pre-login/guards/user-details.guard';

import { NdaLoginComponent } from './nda-login/nda-login.component';
import { NdaNoPageComponent } from './components/nda-no-page/nda-no-page.component';
import { StandardPageLayoutComponent } from './components/standard-page-layout/standard-page-layout.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ChangePasswordPreLoginComponent } from './change-password-pre-login/components/change-password-pre-login/change-password-pre-login.component';
import { ContactsListComponent } from './contacts/components/contacts-list/contacts-list.component';

const appRoutes: Routes = [
  {
    path: '',
    component: StandardPageLayoutComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full'},
      { path: 'pdn-verification', redirectTo: '/register', pathMatch: 'full' },
      { path: 'login', component: NdaLoginComponent, canActivate: [AppGuard]},
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AppGuard]},
      { path: 'manage/users', loadChildren: () => import('./manage-users/manage-users.module').then(m => m.ManageUsersModule), canActivate: [AppGuard, ManageUsersGuard] },
      { path: 'manage/profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AppGuard] },
      { path: 'contacts', component: ContactsListComponent, canActivate: [AppGuard] },
      { path: 'gethelp', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
      { path: 'change-password', component: ChangePasswordPreLoginComponent, canActivate: [ UserDetailsGuard ] },
      { path: '404', component: NdaNoPageComponent},
      { path: 'logout', component: LogoutComponent},
      { path: 'covid-19', loadChildren: './covid/covid.module#CovidModule',canActivate: [ AppGuard ] }
    ]
  },

  // custom page layouts
  { path: 'register/ally', loadChildren: () => import('./registration-ally/registration-ally.module').then(m => m.RegistrationAllyModule) },
  { path: 'register',      loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule), canActivate:[RegisterGuard], data:{process : 'registration'} },

  // catch-all route
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
