<div class="contactWidget">
  <h2>{{CONSTANTS.contactUs}}</h2>
  <p class="normalText">{{CONSTANTS.helpDesk}}</p>
  <h2>{{CONSTANTS.helpDeskPhone}}</h2>

  <table class="mt-3">
    <tr>
      <td>{{CONSTANTS.weekdays}}</td>
      <td>{{CONSTANTS.weekdayHours}}</td>
    </tr>
    <tr>
      <td>{{CONSTANTS.saturday}}</td>
      <td>{{CONSTANTS.saturdayHours}}</td>
    </tr>
  </table>
</div>
