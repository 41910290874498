import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { StoreService } from '../services/store.service';
import { AppConstantsService } from '../services/app-constants.service';
import { BookmarkingService } from '../services/bookmarking.service';
import { CookieService } from 'ngx-cookie';
import { ProfileUserType } from '../models/profile.interface';
import { SpinnerService } from '../loading-spinner/services/spinner/spinner.service';
import { get } from 'lodash';
import { SessionManagementService } from '../services/session-management.service';
import { OktaAuthService } from "@nda/app/services";
import { LaunchDarklyService } from '../services/launchdarkly/launchdarkly.service';
var OktaAuthorizeStatus;
(function (OktaAuthorizeStatus) {
    OktaAuthorizeStatus.AccessDenied = 'access_denied';
    OktaAuthorizeStatus.LoginRequired = 'login_required';
})(OktaAuthorizeStatus || (OktaAuthorizeStatus = {}));
var AppGuard = /** @class */ (function () {
    function AppGuard(router, sessionManager, appConstantsService, storeService, bookmarkingService, oktaAuthService, cookieService, spinnerService, zone, ld) {
        var _this = this;
        this.router = router;
        this.sessionManager = sessionManager;
        this.appConstantsService = appConstantsService;
        this.storeService = storeService;
        this.bookmarkingService = bookmarkingService;
        this.oktaAuthService = oktaAuthService;
        this.cookieService = cookieService;
        this.spinnerService = spinnerService;
        this.zone = zone;
        this.ld = ld;
        this.isFirstRun = true;
        // Fetches LD flags, keeps track of changes, and stores them in session service
        this._subscription = this.ld.flagChange.subscribe(function (flags) {
            _this.showDM = flags['document-manager'].current;
            _this.showADR = flags['dash_app_adr'].current;
            _this.useSCAlternateUrl = flags['dash_app_sc'].current;
            _this.storeService.write('launchDarklyDMFlag', _this.showDM);
            _this.storeService.write('launchDarklyADRFlag', _this.showADR);
            _this.storeService.write('launchDarklySCFlag', _this.useSCAlternateUrl);
        });
    }
    Object.defineProperty(AppGuard.prototype, "isAuthorized", {
        get: function () {
            var profile = this.sessionManager.profile;
            if (profile && this.sessionManager.hasSession) {
                var authorizedUserD = profile.userType == ProfileUserType.dealership;
                var authorizedUserB = profile.userType == ProfileUserType.branch && !!profile.allyRole;
                return authorizedUserD || authorizedUserB;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AppGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        this.currentUrl = state.url;
        // handle external authentication & keep them on the login page
        if (!!this.cookieService.get('ext_auth') && this.currentUrl == this.appConstantsService.login_path) {
            return true;
        }
        // halt the user & delete their session
        else if (this.sessionManager.willDeleteSessionOnNextVisit) {
            this.sessionManager.willDeleteSessionOnNextVisit = false;
            return this.sessionManager.deleteSession()
                .map(function () { return false; })
                .finally(function () { return _this.redirectToLogin(); });
        }
        // If Apigee Redirect URL triggers app guard, stop execution of app guard.
        var browserUrl = window.location.href;
        if (browserUrl.includes("?code=") && browserUrl.includes("&scope=") && browserUrl.includes("&state=")) {
            return false;
        }
        // Check for Okta profile existence, first page load, and whether Apigee details are in session storage.
        /*
          Note: Only for the following scenario do we want to call refresh session endpoint. This handles +1 app logout.
            this.isAuthorized == true
            this.isFirstRun == true
            this.oktaAuthService.getApigeeJwt() == false
        */
        var isAuthorized = this.isAuthorized && this.isFirstRun && !!!this.oktaAuthService.getApigeeJwt();
        var request = isAuthorized ? this.sessionManager.refreshSession() : Observable.of([]);
        return request
            // set flags
            .do(function () { _this.isFirstRun = false; })
            .do(function () { return _this.authorizationError = null; })
            // handle authorization
            .mergeMap(function () { return _this.authorize(); })
            .catch(function (error) {
            _this.authorizationError = error.errorCode;
            return Observable.of([]);
        })
            // get additional user info & navigate user
            .mergeMap(function () { return _this.requestStatus(); })
            .mergeMap(function (status) { return _this.validate(status); })
            // hide loading state
            .do(function () { return _this.spinnerService.hide(); });
    };
    AppGuard.prototype.authorize = function () {
        var _this = this;
        if (this.isAuthorized && !localStorage.getItem('proxyLayerObject')) {
            this.oktaAuthService.ApigeeRedirect();
        }
        else if (this.isAuthorized) {
            return Observable.of([]);
        }
        return Observable.create(function (observer) {
            _this.sessionManager.authorize()
                .then(function () { return observer.next([]); })
                .catch(function (error) { return observer.error(error); });
        });
    };
    AppGuard.prototype.requestStatus = function () {
        var _this = this;
        var isAuthorizedBranchUser = !this.authorizationError && this.sessionManager.hasSession && this.sessionManager.isBranchUser;
        var isNoRoleOrAllyUserRole = !get(this.sessionManager.profile, 'allyRole');
        //  || get(this.sessionManager.profile, 'allyRole') === ProfileAllyRole.user;
        return new Promise(function (resolve) {
            if (isAuthorizedBranchUser && isNoRoleOrAllyUserRole) {
                var userName = get(_this.sessionManager.profile, 'login');
                return _this.sessionManager.requestStatus(userName)
                    .subscribe(function (requestStatus) { return resolve(requestStatus); });
            }
            return resolve({ pendingRequests: false, pendingApprovalRequests: false });
        });
    };
    AppGuard.prototype.validate = function (requestStatus) {
        var _this = this;
        var IS_LOGIN = this.currentUrl == this.appConstantsService.login_path, IS_NDA = !this.cookieService.get('ext_app_name'), NEEDS_APPROVAL = !this.sessionManager.hasBirthright;
        if (IS_NDA && IS_LOGIN && !NEEDS_APPROVAL && !this.authorizationError) {
            this.redirectToDashboard();
        }
        else if (IS_NDA && !IS_LOGIN && NEEDS_APPROVAL && !!this.sessionManager.profile) {
            if (!this.sessionManager.profile.allyRole && this.sessionManager.isDealershipUser) {
                this.sessionManager.deleteSessionOktaSesion().subscribe(function () {
                    _this.redirectToLogin({ isPendingApproval: true });
                });
            }
            if (!this.currentUrl.includes('/pending-requests') && !this.sessionManager.profile.allyRole && this.sessionManager.isBranchUser) {
                this.redirectToAdminRegistration();
            }
        }
        else if (!this.sessionManager.hasSession && !IS_LOGIN) {
            this.bookmarkingService.setBookmarkedUrl(this.currentUrl);
            this.redirectToLogin();
            return Observable.of(false);
        }
        else if (this.sessionManager.hasSession) {
            if (this.sessionManager.isBranchUser && !this.sessionManager.profile.allyRole) {
                if (requestStatus.pendingApprovalRequests) {
                    this.redirectToPendingRequests({ hasApprovalRequests: true });
                }
                else {
                    this.redirectToAdminRegistration();
                }
            }
            else if (this.sessionManager.isBranchUser && IS_LOGIN) {
                this.redirectToDashboard();
            }
            else if (!this.sessionManager.isBranchUser && NEEDS_APPROVAL && !IS_LOGIN) {
                this.sessionManager.deleteSessionOktaSesion().subscribe(function () {
                    _this.redirectToLogin({ isPendingApproval: true });
                });
                return Observable.of(false);
            }
            else if (this.sessionManager.isDealershipUser && IS_LOGIN) {
                this.redirectToDashboard();
            }
        }
        else if (this.authorizationError == OktaAuthorizeStatus.AccessDenied) {
            return Observable.create(function (obs) {
                _this.sessionManager.requestSessionDetails()
                    .then(function (data) {
                    if (data.status == 'ACTIVE') {
                        var login_1 = data.login;
                        _this.sessionManager.requestStatus(login_1, true)
                            .subscribe(function (requestStatus) {
                            if (requestStatus.pendingRequests) {
                                _this.redirectToError({ type: 'pendingRequests' });
                            }
                            else {
                                _this.redirectToRegistration({ type: 'ext', login: login_1 });
                            }
                            obs.next(false);
                        });
                    }
                    obs.next(true);
                });
            });
        }
        return Observable.of(true);
    };
    AppGuard.prototype.redirectToLogin = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.zone.run(function () {
            _this.router.navigate(['/login'], { queryParams: params });
        });
    };
    AppGuard.prototype.redirectToDashboard = function () {
        var _this = this;
        this.zone.run(function () {
            _this.router.navigate(['/dashboard']);
        });
    };
    AppGuard.prototype.redirectToRegistration = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.zone.run(function () {
            _this.router.navigate(['/register'], { queryParams: params });
        });
    };
    AppGuard.prototype.redirectToAdminRegistration = function () {
        var _this = this;
        this.zone.run(function () {
            _this.router.navigate(['/register/ally']);
        });
    };
    AppGuard.prototype.redirectToError = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.zone.run(function () {
            _this.router.navigate(['/error'], { queryParams: params });
        });
    };
    AppGuard.prototype.redirectToPendingRequests = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (this.currentUrl.indexOf('/manage/users/pending-requests') < 0) {
            this.zone.run(function () {
                _this.router.navigate(['/manage/users/pending-requests'], { queryParams: params });
            });
        }
    };
    return AppGuard;
}());
export { AppGuard };
