import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-ally-cancel-modal',
  templateUrl: './ally-cancel-modal.component.html',
  styleUrls: ['./ally-cancel-modal.component.scss']
})
export class AllyCancelModalComponent {

  didConfirm$: Subject<boolean> = new Subject();
  didDecline$: Subject<boolean> = new Subject();

  private modalRef: BsModalRef;

  confirm() {
    this.didConfirm$.next(true);
    this.didConfirm$.complete();
  }

  decline() {
    this.didDecline$.next(false);
    this.didDecline$.complete();
  }
}
