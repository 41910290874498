import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services';
import { AppStrings } from '../../../assets/app-strings/app-strings';

@Component({
  selector: 'nda-no-page',
  templateUrl: './nda-no-page.component.html',
  styleUrls: ['./nda-no-page.component.scss']
})
export class NdaNoPageComponent {

  readonly CONSTANTS = {
   errors: AppStrings['errorsPage']['pageNotAvailable']
  };

  constructor(
    private analytics: AnalyticsService
  ) { }

  ngOnInit() {
    this.analytics.trackPage({ title: 'ADOS:Page Not Found' });
  }

  get error(){
    return this.CONSTANTS.errors;
  }
  

}
