import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppStrings } from '../../../assets/app-strings/app-strings';
import {template} from 'lodash';

@Component({
  selector: 'nda-footer',
  templateUrl: './nda-footer.component.html',
  styleUrls: ['./nda-footer.component.scss']
})
export class NdaFooterComponent {

  readonly CONSTANTS = {
    ...AppStrings['common'],
    ...AppStrings['footer']
  };

  readonly urls = environment.footerUrls;

  public getCopyRightMessage(): string {
    return template(this.CONSTANTS['copyright'])({ currentYear: (new Date()).getFullYear() });
  };
}
