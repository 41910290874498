import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';

@Injectable()
export class StoreService {

  constructor(private constantsService: AppConstantsService) { }

  private getStoreByKey(key: string): Storage {
    switch(key) {
      case 'hasActiveSession':
      case 'willDeleteSession':
        return localStorage;

      default:
        return sessionStorage;
    }
  }

  write(key: string, value: any) {
    if (value) {
      value = JSON.stringify(value);
    }
    this.getStoreByKey(key).setItem(key, value);
  }

  read<T>(key: string): T {
    let value: string = this.getStoreByKey(key).getItem(key);

    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }

    return null;
  }

  delete(key: string): void {
    this.getStoreByKey(key).removeItem(key);
  }

  keyExist(key: string): boolean {
    return this.getStoreByKey(key).getItem(key) !== null;
  }

  clear(): void {
    this.delete(this.constantsService.session_id);
    this.delete(this.constantsService.isActive);
    this.delete(this.constantsService.pdn);
    this.delete(this.constantsService.sessionDetails);
    this.delete(this.constantsService.sessionToken);
    this.delete(this.constantsService.profile);
  }
}
