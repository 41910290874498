import { Injectable } from '@angular/core';
import { NdaModalContentUpdatePersonalInformationComponent } from '../../manage-users/components/nda-modal-content-update-personal-information/nda-modal-content-update-personal-information.component';
import { NdaModalContentRemoveUserComponent } from '../../manage-users/components/nda-modal-content-remove-user/nda-modal-content-remove-user.component';
import { DrawerItem } from '../models/drawer-item.interface';
import { IUserDetails } from '../../manage-users/models/user-details.interface';
import { IApplicationToRequest } from '../../profile/models/application-to-request.interface';
import { IPdnAppList } from '../../profile/models/pdn-app-list.interface';
import { AppConstantsService } from '../../services/app-constants.service';
import { IUserPdnInfo } from '../../profile/models/user-pdn-info.interface';
import { DrawerComponentCommunicatorService } from '../../drawer/services/drawer-component-communicator.service';
import { IApplicationToRequestByAdmin, IRegion } from '../../manage-users/models/application-to-request-by-admin.interface';
import { NdaModalContentAddRemoveApplicationsComponent } from '../../manage-users/components/nda-modal-content-add-remove-applications/nda-modal-content-add-remove-applications.component';
import { NdaAddApplicationReportsComponent } from '../../profile/components/nda-add-application-reports/nda-add-application-reports.component';
import { NdaAddDealershipComponent } from '../../profile/components/nda-add-dealership/nda-add-dealership.component';
import { NdaRemoveApplicationReportsComponent } from '../../profile/components/nda-remove-application-reports/nda-remove-application-reports.component';
import { NdaModalContentCreatePasswordComponent } from '../../manage-users/components/nda-modal-content-create-password/nda-modal-content-create-password.component';
import { IUserGroupsList } from '../../manage-users/models/user-groups-list.interface';

@Injectable()
export class DrawerComponentsService {

  // Edit profile related variables
  public profile : IUserDetails;
  public userId : string;

  // Add applications related variables
  applicationRegions: IRegion[];
  public applicationList : IApplicationToRequestByAdmin[];
  public pdn : string;
  public applicationToRequest : IApplicationToRequest[];
  public removeApps : IUserPdnInfo[];
  public primaryRemoveApps : IPdnAppList[];
  public isRequestorAnAdmin : boolean;
  public selfGroups: IUserGroupsList[];

  // Admin - Add secondary Dealers
  public isAddDealerByAdmin : boolean;

  //Admin - Add secondary apps
  public isAddSecondaryApps : boolean;
  public profileObj : any;
  public secondaryDealershipName : string;
  public dealershipName : string;
  public associatedDealers : string[];

  public processState: string;

  constructor(public appConstantsService : AppConstantsService,
      public drawerComponentCommunicatorService : DrawerComponentCommunicatorService
    ) {
    this.applicationRegions = this.appConstantsService.appRegions;
  }

  public getComponents(componentIdentifier: string) {
    switch(componentIdentifier) {
      case this.drawerComponentCommunicatorService.editPersonalInfoIdentifier:
        return new DrawerItem(NdaModalContentUpdatePersonalInformationComponent, {
          profile: this.profile, userId: this.userId, isRequestorAnAdmin: this.isRequestorAnAdmin
        });

      case this.drawerComponentCommunicatorService.removeUserIdentifier:
        return new DrawerItem(NdaModalContentRemoveUserComponent, {});

      case this.drawerComponentCommunicatorService.dealerAddAssocAppsIdentifier:
        return new DrawerItem(NdaAddDealershipComponent, {
          applicationToRequest: this.applicationToRequest, isAddDealerByAdmin: this.isAddDealerByAdmin, profileObj: this.profileObj, associatedDealers: this.associatedDealers, profile: this.profile, selfGroups: this.selfGroups
        });

      case this.drawerComponentCommunicatorService.addAppsIdentifier:
      case this.drawerComponentCommunicatorService.removeAppsIdentifier:
        return new DrawerItem(NdaModalContentAddRemoveApplicationsComponent, {
          applicationRegions: this.applicationRegions, applicationList: this.applicationList , pdn: this.pdn, isAddSecondaryApps: this.isAddSecondaryApps, profile: this.profile, isRequestorAnAdmin: this.isRequestorAnAdmin
        });

      case this.drawerComponentCommunicatorService.dealerAddAppsIdentifier:
        return new DrawerItem(NdaAddApplicationReportsComponent, {
          applicationToRequest : this.applicationToRequest, pdn: this.pdn, isAddSecondaryApps: this.isAddSecondaryApps, profileObj: this.profileObj, secondaryDealershipName: this.secondaryDealershipName
        });

      case this.drawerComponentCommunicatorService.removeAppsReportIdentifier:
        return new DrawerItem(NdaRemoveApplicationReportsComponent, {
          applicationList: this.isAddSecondaryApps ? this.removeApps : this.primaryRemoveApps
        });

      case this.drawerComponentCommunicatorService.createPasswordIdentifier:
        return new DrawerItem(NdaModalContentCreatePasswordComponent, {
          profile: this.profile
        });
    }
  }


}
