import { Component, ViewChild } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { IProfile, IUserDealership, ProfileAllyRole } from '../../../models/profile.interface';
import { get, pick } from 'lodash';
import { Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { SessionManagementService } from '../../../services/session-management.service';
import { UserService } from '../../../services/user/user.service';


@Component({
  selector: 'session-header',
  templateUrl: './session-header.component.html',
  styleUrls: ['./session-header.component.scss']
})
export class SessionHeaderComponent {

  query: string;

  readonly CONSTANTS: { [key: string]: any } = {
    ...get(AppStrings, 'common'),
    ...get(AppStrings, 'oktaAllyRoles')
  };

  @ViewChild('search', { static: true }) public ngForm;

  get canManage(): boolean {
    return this.sessionManager.canManage;
  }

  get isDealershipUser(): boolean {
    return this.sessionManager.isDealershipUser;
  }

  get isBranchUser(): boolean {
    return this.sessionManager.isBranchUser;
  }

  get dealership(): IUserDealership {
    return get(this.sessionManager, 'profile.dealership');
  }

  get fullName(): string {
    if (!this.sessionManager.profile) {
      return '';
    }

    const { firstName, lastName } = this.sessionManager.profile;
    return `${firstName} ${lastName}`;
  }

  get dealershipDetails(): string {
    if (!this.sessionManager.profile) {
      return '';
    }

    let name = get(this.dealership, 'name'),
      pdn = get(this.dealership, 'pdn');

    return (name ? `${name} - ` : '') + `${this.CONSTANTS.pdn}: ${pdn}`;
  }

  get allyRole(): string {
    if (!this.sessionManager.profile) {
      return '';
    }

    return this.CONSTANTS[this.sessionManager.profile.allyRole];
  }

  get showManage(): boolean {
    return this.sessionManager.showManage;
  }

  get userRole(): string {
    return this.sessionManager.userRole;
  }

  get adminTest(): boolean {
    return this.sessionManager.allyAdminSearchUserBoolean;
  }
  get helpTest(): boolean {
    return this.sessionManager.helpDeskSearchUserBoolean;
  }
  get userTest(): boolean {
    return this.sessionManager.allyUserSearchUserBoolean;
  }


  constructor(
    public router: Router,
    protected storeService: StoreService,
    protected sessionManager: SessionManagementService,
    public userService: UserService,
  ) { }

  handleSubmit(query:string) {
    let truncatedQuery = query.trim();
    this.router.navigate(['/dashboard/search'], {queryParams :{query:truncatedQuery}});
  }

  get term(): string {
    return get(this, 'query', '').replace(/ /g, '');
  }

  get canSubmit(): boolean {
    const hasErrors = get(this.ngForm, 'controls.query.errors.required', false);
    return this.ngForm.submitted ? (hasErrors ? false : this.term.length > 2) : true;
  }

  get isValidQuery(): boolean {
    const hasSubmitted = this.ngForm.submitted;
    const isValid = this.term.length == 0 || this.term.length > 2;
    return !hasSubmitted || (hasSubmitted && isValid);
  }
}
