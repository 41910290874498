import { Component, OnInit } from '@angular/core';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { UserDetailsService } from '../../services/user-details/user-details.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { Router } from '@angular/router';
import { DrawerOutputState, DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { get } from 'lodash';
import { SessionManagementService } from '../../../services/session-management.service';

@Component({
  selector: 'nda-modal-content-remove-user',
  templateUrl: './nda-modal-content-remove-user.component.html',
  styleUrls: ['./nda-modal-content-remove-user.component.scss']
})
export class NdaModalContentRemoveUserComponent implements OnInit {

  readonly CONSTANTS = {
    ...AppStrings['removeUser'],
    ...AppStrings['common']
  };

  constructor(
    private userService: UserDetailsService,
    private appConstantsService: AppConstantsService,
    public router: Router,
    private sessionManagementService : SessionManagementService,
    private drawerComponentCommunicatorService :DrawerComponentCommunicatorService
  ) { }

  ngOnInit() {
  }

  public removeUser() {
    this.userService.removeUser().subscribe((response) => {
      if (get(response, 'error.exceptions')) {
        this.showErrorBanner();
      }
      else {
        if (this.isBranch && this.isAllyRole) {
          this.userService.callOnloadService = true;
          this.drawerComponentCommunicatorService.updateState(null);
        }
        else if (this.isBranch) {
          this.router.navigateByUrl('/manage/users/search-users');
        }
        else {
          this.router.navigateByUrl('/manage/users/pending-requests');
        }
      }
    });
  }

  public showErrorBanner() {
    this.drawerComponentCommunicatorService.updateState( DrawerOutputState.processError );
  }

  get isAllyRole(): boolean {
    return !!this.userService.allyRole;
  }

  get isBranch (): boolean {
    return this.sessionManagementService.isBranchUser;
  }

}
