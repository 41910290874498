import { Injectable } from '@angular/core';
import { IFlowMap } from '../models/flow-map.interface';
import {map} from "lodash";
import {IRegion} from "../manage-users/models/application-to-request-by-admin.interface";
import {filter} from 'lodash';

export interface InterfaceAppsList {
  name: any,
  description: any,
  applicationCode: any,
  applicationGroup: any,
  properties: any
}

@Injectable()
export class AppConstantsService {

  private _apps: InterfaceAppsList[] = [];
  _appCodes: string[] = ['VPC', 'VPC2', 'SC', 'SA', 'SG', 'ARRA', 'RO', 'CBAS', 'SDT', 'CCL', 'WBS','DTAEFRRR'];
  appRegions: IRegion[] = [
                            {regionCode: "C", regionName: "Central"},
                            {regionCode: "NE", regionName: "Northeast"},
                            {regionCode: "PW", regionName: "Pacific West"},
                            {regionCode: "R", regionName: "Remarketing/CPC"},
                            {regionCode: "SE", regionName: "Southeast"},
                            {regionCode: "W", regionName: "West"}
                            ];

  get appList(): InterfaceAppsList[] {
    return this._apps;
  }

  set appList(apps) {
    this._apps = apps;
    this.dealerAppListToBeOpenedInWindow = [];
    this.dealerAppListRequireAccess = [];
    this.appCodesList = map(apps,(app) => {
      this.dealerAppNames[app.applicationCode] = app.name;
      if(app.properties['displayMode'] == 'WINDOW') this.dealerAppListToBeOpenedInWindow.push(app.applicationCode);
      if(app.properties['isRequestableApp'] == 'Y') this.dealerAppListRequireAccess.push(app.applicationCode);
      return app['applicationCode'];
    })
  }

  get appCodesList(): string[] {
    return this._appCodes;
  }

  set appCodesList(apps: string[]) {
    this._appCodes = apps;
  }

  getAppData(appId:string){
    return filter(this.appList,(app) => {
     return app.applicationCode == appId;
    })
  }

  //common constants
  allyAdminApps = [
    {
        name: "Manage Users",
        applicationCode: "MU"
    }
  ];
  not_permitted = "Sorry... you are not permitted... Taking you to Login Page";
  yes= "YES";
  no= "NO";
  isActive= "isUserActive";
  auth_Required= "MFA_REQUIRED";
  session_id = "sessionId";
  isDealerAppErrorToBeShown = [false];
  dealerErrorType : string;
  pendingRequest = 'Pending request';
  accessRequired = 'Access required';
  unExpectedError = 'Unexpected error';
  vpcText = 'VPC';
  vpc2Text = 'VPC2';
  dev = 'DEV';
  sit = 'SIT';
  pdn = 'pdn';
  sessionDetails = 'sessionDetails';
  sessionToken = 'sessionToken';
  profile = 'profile';
  isPdnVerified = 'isPdnVerified';
  pending = 'Pending';
  rejected = 'Rejected';
  approved = 'APPROVED';
  deleted = 'DELETED';
  dealerAppNames = {
    CBAS:  "Credit Balance Agreement Statements",
    CCL:  "Commercial Credit Line Report",
    RO:   "RouteOne",
    SA:    "SmartAuction",
    SC:    "SmartCash",
    SDT:  "Statement of Dealer Transactions",
    SG:   "Ally Ride Return",
    ARRA:  "Ally Ride Return Admin",
    VPC:  "Vehicle Protection Center",
    VPC2: "Vehicle Protection Center",
    WBS:  "Wholesale Billing Statement",
  };
  storefrontAppIdentifier = 'SFW';
  smartAuctionDealershipRoles = ['Dealer', 'Salesperson'];
  smartAuctionDealershipSecondaryRoles = ['Seller', 'Buyer', 'Buyer and Seller'];
  smartAuctionAllyUserRoles = ['Branch', 'EO Admin'];
  smartCashAllyUserRoles = [
    'EO',
    'EO Basic',
    'Key Entry Clerk',
    'Supervisor 1',
    'Supervisor 2',
    'Supervisor 3',
    'Supervisor 4',
    'Supervisor 5',
    'Manager',
    'Support',
    'SPB'
  ];
  smartCashAllyUserRegion = [
    'Central',
    'Northeast',
    'Pacific West',
    'Remarketing/CPC',
    'Southeast',
    'West',
  ];
  allyRideReturnDealershipRoles = ['primaryAccess', 'secondaryAccess', "fullAccess"];
  allyRideReturnAdminDealershipRoles = ['primaryAccess'];
  isUserNameLinkClicked = false;
  adminSearchFlag : boolean = false;

  template = {
    spinner: '<img class="nda-spinner" src="assets/images/Spinner_White.svg" />'
  };

  dealerAppListRequireAccess = ['VPC', 'VPC2', 'SC', 'SA', 'SG', 'ARRA', 'RO', 'CBAS', 'SDT', 'CCL', 'WBS','DTAEFRRR'];
  dealerAppListToBeOpenedInWindow = ['SA', 'ARRA'];
  smartAuction = 'SA';
  keepAliveTime: number = 1380; // 23 mins - Refresh Session call
  idleTime: number      = 1440; // 24 mins - Modal popup
  idleTimeOut: number   =  300; // 05 mins - Auto logout after modal popup
  fullWidthIframe: string[] = ['RO', 'SC'];

  //router paths
  login_path = "/login";
  splash_path = "/coming-soon";
  dashboard_path = "/dashboard";
  user_Details_path = "/manage/users/search-users/user-details";
  pre_login_change_password_path = "/change-password";
  post_login_change_password = "/manage/profile/change-password";
  otp_login_flow : IFlowMap = { trigger: "login", routeTo: "/dashboard"};
  otp_registration_flow : IFlowMap = { trigger: "resitration", routeTo: "/register/confirmation"};
  otp_forgotPwd_flow : IFlowMap = { trigger: "forgotPwd", routeTo: "/gethelp/reset-password"};
  pdn_verified_register_flow : IFlowMap = {trigger: "pdnVerifiedRegister", routeTo: "/register"};
  pdn_non_verified_register_flow : IFlowMap = {trigger: "pdnNonVerifiedRegister", routeTo: "/pdn-verification"};
  edit_profile_flow : IFlowMap = { trigger: "editProfile", routeTo: "/manage/profile"};

}
