import { Injectable } from '@angular/core';
import { IState, IUSStateService } from './definitions';
import { STATES } from './mock-states';

@Injectable()
export class UsStatesService implements IUSStateService {

  constructor() { }

  public getStates(): IState[] {
    return STATES;
  }
}
