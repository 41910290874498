<article class="modal-body">
  <h1>Don't Save Changes?</h1>
  <p>If you leave without submitting the edits, your changes will be lost.</p>

  <div class="button-container">
    <button type="button" class="btn btn-primary font-weight-bold" (click)="confirm()">Continue</button>
    <button type="button" class="btn btn-link accessibility-button-group" (click)="decline()">Go Back</button>
  </div>
</article>

